<template>
  <div class="doc-detail-container">
    <div class="banner">
      <head-navigation class="txd"></head-navigation>
      <div class="rack-top">
        <div class="header">
          技术支持>>{{ form.productName }}>> {{ form.productModel }}>><span
            >案例详情</span
          >
        </div>
        <div class="rack-top-detail">
          <div class="detail">
            <div class="label">{{ form.exampleName }}</div>
            <div class="msg">
              <a-row>
                <a-col :span="4">
                  <div class="col">
                    <span class="col-title">案例编号:</span>
                    <span class="col-content">{{ form.exampleCode }}</span>
                  </div>
                </a-col>
                <a-col :span="7">
                  <div class="col">
                    <span class="col-title">产品型号:</span>
                    <span class="col-content">{{ form.productModels }}</span>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="col">
                    <span class="col-title">文档密级:</span>
                    <span class="col-content">
                      <span v-if="form.exampleLevel == 0"
                        >S级-仅限思腾工程师开放的通用案例</span
                      >
                      <span v-if="form.exampleLevel == 1"
                        >A级-面向服务渠道开放的通用案例</span
                      >
                      <span v-if="form.exampleLevel == 2"
                        >C级-面向购买产品的客户开放的通用案例</span
                      >
                      <span v-if="form.exampleLevel == 3"
                        >D级-面向注册用户开放的通用案例</span
                      >
                      <span v-if="form.exampleLevel == 4"
                        >E级-面向访客开放的通用案例</span
                      ></span
                    >
                  </div></a-col
                >
                <a-col :span="6"
                  ><div class="col">
                    <span class="col-title">发布时间:</span>
                    <span class="col-content">2022-10-29 15:17:38</span>
                  </div></a-col
                >
              </a-row>
              <!-- <a-row>
                <a-col :span="6"
                  ><div class="col">
                    <span class="col-title">浏览次数:</span>
                    <span class="col-content">7950</span>
                  </div></a-col
                >
              </a-row> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div style="width: 100%; height: 100%">
        <VueOfficePdf :src="pdfUrl" style="width: 100%; height: 100%" />
      </div>
      <!-- <a-row justify="center" style="height: 100%">
        <a-col :span="19"><div class="docRight">sssss</div></a-col>
        <a-col :span="5">
          <div
            class="right-navigate"
            :style="{ height: scrollHeight + 'px' }"
            :class="
              windowScrollTop >= windowBannerHeight && windowScrollTop !== 0
                ? 'active'
                : ''
            "
          >
            <div class="right-scroll">
              <div class="scroll-item-title">本页目录</div>
              <ul class="scroll-item-child">
                <li
                  :class="currentDoc === item.id ? 'active' : ''"
                  @click="handleClick(item)"
                  v-for="item in docNavList"
                  :key="item"
                >
                  <a href="javascript:void(0)" @click="goAnchor(item.id)">{{
                    item.name
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </a-col>
      </a-row> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import HeadNavigation from '@/components/HeadNavigation.vue'
import { defineComponent, ref, onMounted, reactive } from 'vue'
import Footer from '@/components/footer/Footer'
import { useRoute } from 'vue-router'
import http from '@/utils/http'
import VueOfficePdf from '@vue-office/pdf'
export default defineComponent({
  components: {
    HeadNavigation,
    Footer,
    VueOfficePdf
  },
  setup () {
    const route = useRoute()
    const form = reactive({})
    const pdfUrl = ref()
    const currentDoc = ref()
    const windowScrollTop = ref(0)
    const windowBannerHeight = ref(0)
    const scrollHeight = ref(
      window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    )
    const activeIndex = ref(0)
    const docNavList = ref([
      {
        name: '了解产品',
        id: '1',
        icon: 'ic_understandproduct'
      },
      {
        name: '快速系列',
        id: '2',
        icon: 'ic_fast'
      },
      {
        name: '安装升级',
        id: '3',
        icon: 'ic_upgradation'
      },
      {
        name: '配置调试',
        id: '4',
        icon: 'ic_configuration'
      },
      {
        name: '操作维护',
        id: '5',
        icon: 'ic_operate'
      },
      {
        name: '故障处理',
        id: '6',
        icon: 'ic_fault'
      },
      {
        name: '参考指南',
        id: '7',
        icon: 'ic_guide'
      },
      {
        name: '二次开发',
        id: '8',
        icon: 'ic_develop'
      },
      {
        name: '其他',
        id: '9',
        icon: 'ic_else'
      },
      {
        name: '文档合集',
        id: '10',
        icon: 'ic_all'
      },
      {
        name: '操作维护',
        id: '11',
        icon: 'ic_operate'
      },
      {
        name: '故障处理',
        id: '12',
        icon: 'ic_fault'
      },
      {
        name: '参考指南',
        id: '13',
        icon: 'ic_guide'
      },
      {
        name: '二次开发',
        id: '14',
        icon: 'ic_develop'
      },
      {
        name: '其他',
        id: '15',
        icon: 'ic_else'
      },
      {
        name: '文档合集',
        id: '16',
        icon: 'ic_all'
      }
    ])
    onMounted(() => {
      getList()
      // if (docNavList.value.length > 0) {
      //   currentDoc.value = docNavList.value[0].id
      // }
      // // 监听滚动事件
      // window.addEventListener('scroll', onScroll)
    })
    const getList = () => {
      if (route.query) {
        http
          .request({
            method: 'get',
            url: `/website/product/home/queryExampleById/${route.query.id}`
          })
          .then((res) => {
            if (res) {
              for (var i in res.data) {
                form[i] = res.data[i]
              }
              form.productName = route.query.productName
              form.productModel = route.query.productModel
              pdfUrl.value = '/api/website' + res.data.picture
            }
            // dataSource.value = res.data.rows;
            // total.value = res.data.total;
          })
      }
    }
    // 移除监听器
    // onBeforeUnmount(() => {
    //   window.removeEventListener('scroll', onScroll)
    // })
    // 滚动监听器
    function onScroll () {
      const bannerHeight = document.getElementsByClassName('banner')[0].offsetHeight + 64
      windowBannerHeight.value = bannerHeight
      // 获取所有锚点元素
      const navContents = document.querySelectorAll('.docRight ul')
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop + bannerHeight)
      })
      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      windowScrollTop.value = scrollTop
      // 定义当前点亮的导航下标
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n
        }
      }
      // 把下标赋值给 vue 的 data
      currentDoc.value = docNavList.value[navIndex].id

      // 判断若接近滚动至底部footer则计算左侧导航高度进行滚动显示
      const footerTop = document
        .getElementsByClassName('footer')[0]
        .getBoundingClientRect().top
      scrollHeight.value = footerTop
      document.getElementsByClassName('right-navigate')[0].style.overflowX = 'hidden'
      document.getElementsByClassName('right-navigate')[0].style.overflowY = 'auto'
    }
    return {
      activeIndex,
      currentDoc,
      windowScrollTop,
      windowBannerHeight,
      scrollHeight,
      docNavList,
      form,
      pdfUrl,
      onScroll
    }
  }
})
</script>

<style lang="scss" scoped>
.doc-detail-container {
  .download-btn {
    margin-left: 8px;
    border: unset;
    background: #ffffff;
    padding: 5px 13px;
    cursor: pointer;
    font-size: 14px;
    color: #3273f9;
    letter-spacing: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .banner {
    height: 30%;
    border: 1px solid #979797;
    background: #3273f9;
    min-height: 330px;
    .txd {
      position: relative;
    }
    .header {
      margin: 0 auto;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: 0;
      font-weight: 500;
      span {
        color: #ffffff;
      }
    }
    .rack-top {
      padding: 0 19%;
      margin: 0 auto;
      .rack-top-detail {
        // display: flex;
        .detail {
          margin-top: 24px;
          .label {
            background-image: linear-gradient(
              -86deg,
              rgba(50, 115, 249, 0) 0%,
              rgba(255, 255, 255, 0.45) 100%
            );
            font-size: 30px;
            color: #ffffff;
            letter-spacing: 0;
            font-weight: 700;
            padding: 7px 9px 7px 9px;
          }
          .msg {
            margin-top: 10px;
            font-size: 16px;
            color: #ffffff;
            font-weight: 400;
            line-height: 32px;
            .col {
              align-items: center;
              // line-height: 42px;
            }

            .col-title {
              font-size: 14px;
              color: #ffffff;
              letter-spacing: 0;
              text-align: right;
              font-weight: 400;
              width: 70px;
              float: left;
              align-items: center;
            }

            .col-content {
              font-size: 14px;
              color: #ffffff;
              font-weight: 700;
              text-align: justify;
              word-break: break-all;
              display: flow-root;
            }
          }
        }
      }
    }
  }
  .tabs {
    height: 64px;
    border: unset;
    box-shadow: 0 2px 27px 0 rgba(144, 161, 230, 0.5);
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
      float: left;
      width: 46%;

      li {
        height: 64px;
        line-height: 64px;
        margin: 0 5%;
        box-sizing: border-box;
        font-size: 14px;
        color: #474747;
        cursor: pointer;
      }

      li.deafult {
        font-weight: 200;
      }

      li.active {
        font-weight: 500;
        color: #3273f9;
      }
    }
  }
  .content {
    height: 100vh;
    background: #f8f8fd;
    padding: 0 19%;
    padding-top: 24px;
  }
  .right-navigate {
    height: 100%;
    min-width: 180px;
    top: 0;
    position: absolute;
    background: #eef3fe;
    width: 100%;
    &.active {
      position: fixed;
      width: calc(100% - 1665px);
    }
  }
  .right-scroll {
    width: 100%;
    overflow-y: auto;
    padding: 20px 0px 0 15px;
    .scroll-item-title {
      font-size: 16px;
      color: #474747;
      font-weight: 700;
      margin-left: 15%;
    }
    ul {
      font-size: 16px;
      list-style: none;
      li {
        line-height: 40px;
        // list-style-type: square;
        text-align: left;
        position: relative;
        cursor: pointer;
        color: #474747;
        a {
          text-decoration: none;
          background-color: none;
          color: #474747;
          &:hover {
            color: #0c5dff;
          }
        }
      }
      li::marker {
        font-size: 22px;
        color: #b8cefa;
      }
      li.active {
        color: #0c5dff;
        font-weight: 500;
        a {
          color: #0c5dff;
        }
      }
      li.active::marker {
        font-size: 22px;
        color: #0c5dff;
      }
    }
  }
}
</style>
