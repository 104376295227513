<template>
  <div class="product-search">
    <div class="banner">
      <head-navigation class="txd"></head-navigation>
      <div class="rack-top">
        <div class="header" v-if="searchVisible">技术支持>>机架服务器>> 1288H V5>><span>文档详情</span></div>
        <div class="header" v-else>技术支持>><span>搜索详情</span></div>
        <div class="rack-top-detail">
          <button class="back-btn" v-if="searchVisible">返回</button>
          <div class="rack-msg" :style="{ 'margin-top': !searchVisible ? '100px' : '20px' }">
            <div class="rack-title" style="width: 30%" v-if="searchVisible">1288H V5</div>
            <div style="width: 30%" v-else></div>
            <div class="s-search-input" style="width: 40%">
              <input placeholder="请输入关键词搜索" />
              <svg-icon icon-class="ic_search_grey"></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search-menu-layout">
      <a-menu
        v-model:openKeys="openKeys"
        v-model:selectedKeys="selectedKeys"
        class="search-menu-select"
        mode="inline"
        :items="menus"
      />
      <div style="width: 56%">
        <div class="tabs">
          <ul>
            <template v-for="(item, index) in tabList" :key="item.title">
              <li
                :class="activeIndex === index ? 'active' : 'default'"
                @click="handleClick(index, item)"
              >
                {{ item.title }}
              </li>
            </template>
          </ul>
        </div>
        <div class="content">
          <div class="alert" v-if="searchVisible">
            <svg-icon icon-class="ic_tips" class="head-icon"></svg-icon>
            <div>当前仅展现了部分资源，请<span>登录</span>后查看更多资源</div>
          </div>
          <a-row justify="space-between" align="bottom" >
            <a-col>
              <div style="display: flex" @click.stop v-if="searchVisible">
                <div
                  class="search-product-type"
                  @click="drowpdownSelectVisible = !drowpdownSelectVisible"
                >
                  <div class="search-product-word">产品类别</div>
                  <div class="search-product-down" v-if="drowpdownSelectVisible">
                    <svg-icon icon-class="ic_xia"></svg-icon>
                  </div>
                  <div class="search-product-up" v-else>
                    <svg-icon icon-class="ic_xia"></svg-icon>
                  </div>
                </div>
                <div class="delete-product-type" v-show="selectTagVisible">
                  <div>{{ selectTag }}</div>
                  <svg-icon icon-class="ic_chahao" @click="handleDel"></svg-icon>
                </div>
              </div>

              <div
                class="search-product-template"
                ref="drowpdownSelectRef"
                v-show="drowpdownSelectVisible"
              >
                <div class="list-item" v-for="item in productCategoryList" :key="item">
                  <div class="list-item-title" @click="item.visible = !item.visible">
                    <div class="text">{{ item.label }}</div>
                    <div class="icon-up" v-if="item.visible">
                      <svg-icon icon-class="ic_xia"></svg-icon>
                    </div>
                    <div class="icon-down" v-else>
                      <svg-icon icon-class="ic_xia"></svg-icon>
                    </div>
                  </div>
                  <div class="list-item-detail" v-show="item.visible">
                    <div
                      class="detail-item"
                      v-for="items in item.children"
                      :key="items"
                      @click="handleSelect(item, items)"
                    >
                      {{ items.title }}
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col>
              <div class="sort">
                <svg-icon icon-class="ic_xiangguan"></svg-icon>
                <div class="text">相关度</div>
                <svg-icon icon-class="ic_liulan"></svg-icon>
                <div class="text">浏览量</div>
                <svg-icon icon-class="ic_shijian"></svg-icon>
                <div class="text">发布时间</div>
              </div>
            </a-col>
          </a-row>
          <ul class="search-product-list">
            <li class="table" v-for="item in tableData" :key="item">
              <div class="table-item">
                <div class="table-item-title">
                  <div>{{ item.title }}</div>
                  <svg-icon icon-class="ic_lock"></svg-icon>
                  <svg-icon icon-class="ic_load"></svg-icon>
                </div>
                <div class="table-item-detail">
                  {{ item.detail }}
                </div>
                <div class="table-item-msg">{{ item.msg }}</div>
                <div class="table-item-path">
                  技术支持>机架服务器>128HH V5><span>文档</span>
                </div>
              </div>
              <div class="table-date">{{ item.date }}</div>
            </li>
          </ul>
          <div class="pagination-box">
            <a-pagination
              :total="total"
              v-model:current="searchForm.pageReq.pageNum"
              show-quick-jumper
              :showTotal="(total) => `共 ${total} 条`"
              size="small"
              show-size-changer
            ></a-pagination>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import HeadNavigation from '@/components/HeadNavigation.vue'
import { defineComponent, ref, onMounted, reactive } from 'vue'
import Footer from '@/components/footer/Footer'
import SvgIcon from '@/components/svg-icon/SvgIcon.vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  components: {
    HeadNavigation,
    Footer,
    SvgIcon
  },
  setup () {
    const activeIndex = ref(0)
    const selectedKeys = ref(['10'])
    const openKeys = ref(['1', '2', '3', '16'])
    const route = useRoute()
    const searchVisible = ref(false)
    const total = ref(0)
    const searchForm = reactive({
      pageReq: {
        pageNum: 1,
        pageSize: 10
      }
    })
    const menus = ref([
      {
        key: '1',
        label: '结果分类',
        title: '结果分类',
        children: [
          {
            key: '10',
            label: '全部',
            title: '全部'
          },
          {
            key: '11',
            label: '文档',
            title: '文档'
          }
        ]
      },
      {
        key: '2',
        label: '查询范围',
        title: '查询范围',
        children: [
          {
            key: '12',
            label: '所有',
            title: '所有'
          },
          {
            key: '13',
            label: '标题',
            title: '标题'
          },
          {
            key: '14',
            label: '正文',
            title: '正文'
          }
        ]
      },
      {
        key: '16',
        label: '发布时间',
        title: '发布时间',
        children: [
          {
            key: '3',
            label: '所有时间',
            title: '所有时间'
          },
          {
            key: '4',
            label: '1天内',
            title: '1天内'
          },
          {
            key: '5',
            label: '1周内',
            title: '1周内'
          },
          {
            key: '6',
            label: '1月内',
            title: '1月内'
          },
          {
            key: '7',
            label: '1年内',
            title: '1年内'
          }
        ]
      }
    ])
    const tabList = ref([
      {
        id: '1',
        title: '全部',
        path: 'SoftVersion'
      },
      {
        id: '2',
        title: '文档',
        path: 'SoftProfile'
      },
      {
        id: '3',
        title: '软件',
        path: 'SoftProfile'
      },
      {
        id: '4',
        title: '案例',
        path: 'SoftProfile'
      },
      {
        id: '5',
        title: '产品公告',
        path: 'SoftProfile'
      }
    ])
    const productCategoryList = ref([
      {
        label: '机架服务器',
        visible: false,
        children: [
          {
            id: '1',
            title: 'x8611111'
          },
          {
            id: '2',
            title: 'x8611112'
          },
          {
            id: '3',
            title: 'x8611113'
          },
          {
            id: '4',
            title: 'x8611114'
          }
        ]
      },
      {
        label: 'GPU服务器',
        visible: false,
        children: [
          {
            id: '1',
            title: 'x8611111'
          },
          {
            id: '2',
            title: 'x8611112'
          },
          {
            id: '3',
            title: 'x8611113'
          },
          {
            id: '4',
            title: 'x8611114'
          }
        ]
      },
      {
        label: '刀片服务器',
        visible: false,
        children: [
          {
            id: '1',
            title: 'x8611111'
          },
          {
            id: '2',
            title: 'x8611112'
          },
          {
            id: '3',
            title: 'x8611113'
          },
          {
            id: '4',
            title: 'x8611114'
          }
        ]
      }
    ])
    const selectTag = ref()
    const selectTagVisible = ref(false)
    const drowpdownSelectVisible = ref(false)
    const drowpdownSelectRef = ref()
    const tableData = ref([
      {
        title: 'FusionServer 机架服务器 iBMC (V3.02.00.00及以上) 用户指南 05',
        detail:
          ' CLI说明 格式说明格式说明iBMC管理软件常用命令有以下命令：查询命令ipmcget和设置命令ipmcset的参数说明如下',
        msg: 'CLI说明',
        date: '2022-11-14'
      },
      {
        title: 'FusionServer 机架服务器 iBMC (V3.02.00.00及以上) 用户指南 05',
        detail:
          ' CLI说明 格式说明格式说明iBMC管理软件常用命令有以下命令：查询命令ipmcget和设置命令ipmcset的参数说明如下',
        msg: 'CLI说明',
        date: '2022-11-18'
      }
    ])
    onMounted(() => {
      if (route.query) {
        console.log(route.query)
        if (route.query.type === '0') {
          searchVisible.value = false
        } else {
          searchVisible.value = true
        }
      }
      // 点击下拉菜单界面区域外隐藏下拉列表
      document.addEventListener('click', clickOut)
    })
    function clickOut (e) {
      const drop = drowpdownSelectRef.value
      if (drop && !drop.contains(e.target) && drowpdownSelectVisible.value) {
        drowpdownSelectVisible.value = false
      }
    }
    const handleSelect = (item, items) => {
      // console.log(item)
      // console.log(items)
      selectTag.value = items.title
      selectTagVisible.value = true
      drowpdownSelectVisible.value = false
    }
    const handleDel = () => {
      selectTag.value = null
      selectTagVisible.value = false
      drowpdownSelectVisible.value = false
    }
    const handleClick = (index, item) => {
      activeIndex.value = index
    }
    return {
      activeIndex,
      tabList,
      menus,
      selectedKeys,
      openKeys,
      drowpdownSelectVisible,
      handleSelect,
      drowpdownSelectRef,
      productCategoryList,
      selectTag,
      selectTagVisible,
      handleDel,
      tableData,
      total,
      searchForm,
      handleClick,
      searchVisible
    }
  }
})
</script>

<style lang="scss" scped>
.product-search {
  display: flex;
  flex-direction: column;
  .banner {
    height: 30%;
    border: 1px solid #979797;
    background: #3273f9;
    min-height: 330px;
    .txd {
      position: relative;
    }
    .header {
      margin: 0 auto;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: 0;
      font-weight: 500;
      span {
        color: #ffffff;
      }
    }
    .rack-top {
      padding: 0 19%;
      margin: 0 auto;
      .rack-top-detail {
        margin-top: 30px;
        .back-btn {
          border: unset;
          background: transparent;
          padding: 6px 12px;
          cursor: pointer;
          font-size: 14px;
          color: #ffffff;
          letter-spacing: 0;
          font-weight: 500;
          border: 1px solid #ffffff;
        }
        .rack-msg {
          display: flex;
          align-items: center;
          margin-top: 20px;
          .rack-title {
            background-image: linear-gradient(
              -86deg,
              rgba(50, 115, 249, 0) 0%,
              rgba(255, 255, 255, 0.45) 100%
            );
            font-size: 30px;
            color: #ffffff;
            letter-spacing: 0;
            font-weight: 700;
            padding: 7px 9px 7px 9px;
          }
        }
      }
    }
  }
  .search-menu-layout {
    display: flex;
    justify-content: center;
    .search-menu-select {
      width: 13%;
      background: #eef3fe;
      .ant-menu {
        .ant-menu-item-selected {
          color: #0c5dff;
          background-color: transparent;
        }
      }
    }
    .tabs {
      height: 64px;
      border: unset;
      box-shadow: 0 2px 27px 0 rgba(144, 161, 230, 0.5);
      align-items: center;
      justify-content: flex-start;
      display: flex;
      position: relative;
      width: 100%;
      ul {
        list-style: none;
        padding: 0 0 0 10%;
        margin: 0;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        float: left;
        width: 66%;

        li {
          height: 64px;
          line-height: 64px;
          margin: 0 5%;
          box-sizing: border-box;
          font-size: 14px;
          color: #474747;
          cursor: pointer;
        }

        li.deafult {
          font-weight: 200;
        }

        li.active {
          font-weight: 700;
          font-size: 16px;
          border-bottom: 4px solid #3273f9;
        }
      }
    }
  }
  .content {
    height: 100%;
    padding: 16px;
    .alert {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #e05e13;
      font-weight: 400;
      span {
        color: #3273f9;
        font-weight: 700;
      }
      svg {
        margin-right: 10px;
        margin-top: -3px;
        width: 14px;
        height: 14px;
      }
    }
    .sort {
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        width: 14px;
        height: 14px;
      }
      .text {
        margin-right: 20px;
        margin-left: 10px;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .search-product-list {
      margin-top: 12px;
      .table {
        display: flex;
        align-items: center;
        background: #ffffff;
        border: 1px solid #e9eaef;
        padding: 14px 24px;
        line-height: 28px;
        border-bottom: unset;
        .table-item {
          width: 80%;
          svg {
            width: 16px;
            height: 16px;
            margin-left: 6px;
          }
          .table-item-title {
            font-size: 14px;
            color: #474747;
            font-weight: 400;
            display: flex;
            align-items: center;
          }
          .table-item-detail {
            font-size: 12px;
            color: #959697;
            font-weight: 400;
          }
          .table-item-path {
            font-size: 14px;
            color: #959697;
            font-weight: 400;
            span {
              color: #474747;
            }
          }
        }
        .table-date {
          font-size: 14px;
          color: #474747;
          font-weight: 400;
        }
        &:last-child {
          border-bottom: 1px solid #e9eaef;
        }
      }
    }

    .search-product-type {
      display: flex;
      align-items: center;
      height: 36px;
      display: flex;
      padding: 8px 16px;
      box-sizing: border-box;
      background: #fff;
      border: 1px solid #b7b7b9;
      cursor: pointer;
      svg {
        width: 14px;
        height: 14px;
      }
      .search-product-word {
        font-size: 14px;
        color: #c5c7cc;
        font-weight: 500;
      }
      .search-product-down {
        margin-left: 50px;
      }
      .search-product-up {
        margin-left: 50px;
        transform: rotatex(180deg);
      }
    }
    .search-product-template {
      min-width: 760px;
      padding: 20px;
      box-sizing: border-box;
      position: absolute;
      background: #fff;
      border: 1px solid #dfe1e6;
      box-shadow: 0 12px 32px 0 rgba(190, 196, 204, 0.2);
      border-radius: 2px;
      z-index: 8;
      top: 40px;
      .list-item {
        width: 100%;
        .list-item-title {
          width: 100%;
          padding: 0px 0 18px 12px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          cursor: pointer;
          .text {
            font-size: 14px;
            color: #474747;
            font-weight: 600;
          }
          .icon-up {
            margin-left: auto;
          }
          .icon-down {
            margin-left: auto;
            transform: rotatex(180deg);
          }
          svg {
            width: 10px;
            height: 10px;
          }
        }
        .list-item-detail {
          padding: 8px 12px 8px 12px;
          margin-bottom: 12px;
          box-sizing: border-box;
          display: flex;
          flex-flow: row wrap;
          align-content: flex-start;
          background: #f9f8f8;
          .detail-item {
            height: 28px;
            font-size: 14px;
            color: #474747;
            line-height: 28px;
            font-weight: 400;
            flex: 0 0 25%;
            cursor: pointer;
          }
        }
      }
    }
    .delete-product-type {
      border: 1px solid #979797;
      font-size: 14px;
      color: #474747;
      font-weight: 400;
      display: flex;
      align-items: center;
      padding: 5px 8px;
      margin-left: 16px;
      width: 134px;
      svg {
        width: 8px;
        height: 8px;
        margin-left: auto;
        cursor: pointer;
      }
    }
    .pagination-box {
      padding-top: 16px;
      padding-bottom: 16px;
      text-align: right;
    }
  }
}
</style>
