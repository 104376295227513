<template>
  <div class="mall-container">
    <div class="banner-box">
      <a-carousel autoplay>
        <div class="img-box">
          <img src="../../assets/img/banner.png" alt="" style="width: 100%;">
        </div>
      </a-carousel>
      <head-navigation/>
      <div class="input-box">
        <input
          type="text"
          placeholder="搜索算法"
          v-model="algorithmNameRef"
          @keyup.enter="handleSearch"
        />
        <svg-icon icon-class="ic_search" @click="handleSearch"/>
      </div>
    </div>
    <filter-condition
      :scene-dict="sceneDict"
      :industry-dict="industryDict"
      :industryIds="algorithmParams.industryIds"
      :sceneIds="algorithmParams.sceneIds"
      :other="algorithmParams.other"
      @setIndustryIds="getIndustryIds"
      @removeIndustryIds="handleRemoveIndustryIds"
      @removeSceneIds="handleRemoveSceneIds"
      @setSceneIds="getSceneIds"
      @setOther="getOther"
      @showMore="handleShowMore"
      @showMoreIndustry="handleShowMoreIndustry"
    />
    <application-scenario
      @link="handleLink"
      :algorithm-data="algorithmData"
    />
    <div class="pagination-box">
      <a-pagination
        :total="total"
        show-size-changer
        show-quick-jumper
        size="small"
        :show-total="total => `共 ${total} 条`"
        :page-size="algorithmParams.pageSize"
        :current="algorithmParams.pageNum"
        @change="handleChangePage"
      />
    </div>
    <div class="application-use">
      <img src="../../assets/img/wenzi.png" alt="">
      <button @click="handleApply">点击申请试用</button>
    </div>
    <Footer/>
    <ApplyTry v-model:open="open"/>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import FilterCondition from './component/FilterCondition'
import ApplicationScenario from './component/ApplicationScenario'
import Footer from '../../components/footer/Footer'
import HeadNavigation from '@/components/HeadNavigation'
import ApplyTry from '@/components/apply-try/ApplyTry.vue'
import { useRouter, useRoute } from 'vue-router'
import http from '@/utils/http'

export default defineComponent({
  components: {
    FilterCondition,
    ApplicationScenario,
    Footer,
    HeadNavigation,
    ApplyTry
  },
  setup () {
    const router = useRouter()
    const route = useRoute()

    const sceneDict = ref([])
    const allData = ref([])
    const querySceneDict = () => {
      http.request({
        method: 'get',
        url: '/website/scene/home/querySceneDict'
      }).then(res => {
        if (res.data.length > 10) {
          sceneDict.value = res.data.slice(0, 10)
        } else {
          sceneDict.value = res.data
        }
        allData.value = res.data
      })
    }

    const handleShowMore = (type) => {
      if (type) {
        sceneDict.value = allData.value
      } else {
        sceneDict.value = allData.value.slice(0, 10)
      }
    }

    const industryDict = ref([])
    const allDataIndustry = ref([])
    const queryIndustryDict = () => {
      http.request({
        method: 'get',
        url: '/website/industry/home/queryIndustryDict'
      }).then(res => {
        industryDict.value = res.data
        if (res.data.length > 10) {
          industryDict.value = res.data.slice(0, 10)
        } else {
          industryDict.value = res.data
        }
        allDataIndustry.value = res.data
      })
    }

    const handleShowMoreIndustry = (type) => {
      if (type) {
        industryDict.value = allDataIndustry.value
      } else {
        industryDict.value = allDataIndustry.value.slice(0, 10)
      }
    }

    const getIndustryIds = (params) => {
      if (params === '') {
        algorithmParams.industryIds = []
      } else {
        algorithmParams.industryIds.push(params)
      }
      algorithmParams.pageNum = 1
      queryAlgorithm()
    }

    const handleRemoveIndustryIds = (params) => {
      const s = new Set(algorithmParams.industryIds)
      s.delete(params)
      algorithmParams.industryIds = Array.from(s)
      algorithmParams.pageNum = 1
      queryAlgorithm()
    }

    const getSceneIds = (params) => {
      if (params === '') {
        algorithmParams.sceneIds = []
      } else {
        algorithmParams.sceneIds.push(params)
      }
      algorithmParams.pageNum = 1
      queryAlgorithm()
    }

    const handleRemoveSceneIds = (params) => {
      const s = new Set(algorithmParams.sceneIds)
      s.delete(params)
      algorithmParams.sceneIds = Array.from(s)
      algorithmParams.pageNum = 1
      queryAlgorithm()
    }

    const getOther = (params) => {
      algorithmParams.other = params
      algorithmParams.pageNum = 1
      queryAlgorithm()
    }

    const algorithmData = ref([])
    const algorithmNameRef = ref(route.query.name || '')
    const total = ref(0)

    const algorithmParams = reactive({
      industryIds: [],
      other: 0,
      sceneIds: [],
      pageNum: 1,
      pageSize: 8,
      algorithmName: algorithmNameRef.value
    })

    const queryAlgorithm = () => {
      http.request({
        method: 'post',
        url: '/website/algorithm/home/queryAlgorithmPage',
        data: algorithmParams
      }).then(res => {
        total.value = res.data.total
        algorithmData.value = res.data.rows
      })
    }

    const handleChangePage = (n, s) => {
      algorithmParams.pageNum = n
      algorithmParams.pageSize = s
      queryAlgorithm()
    }

    const handleSearch = () => {
      algorithmParams.algorithmName = algorithmNameRef.value
      algorithmParams.pageNum = 1
      queryAlgorithm()
    }

    const handleLink = (id) => {
      router.push({
        path: '/algorithm-mall-detail',
        query: {
          id: id
        }
      })
    }

    const open = ref(false)

    const handleApply = () => {
      open.value = true
    }

    onMounted(() => {
      querySceneDict()
      queryAlgorithm()
      queryIndustryDict()
    })

    return {
      handleLink,
      open,
      handleApply,
      sceneDict,
      industryDict,
      algorithmParams,
      getIndustryIds,
      getSceneIds,
      getOther,
      total,
      algorithmData,
      handleChangePage,
      handleShowMore,
      handleRemoveIndustryIds,
      handleRemoveSceneIds,
      algorithmNameRef,
      handleSearch,
      handleShowMoreIndustry
    }
  }
})
</script>
<style lang="scss" scoped>
  .mall-container {
    :deep(ul) {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    background-color: #F8F8FD;
    height: 100%;
    overflow-y: auto;
    .banner-box {
      position: relative;
      .input-box {
        width: 474px;
        height: 48px;
        box-sizing: border-box;
        border-width: 3px;
        border-style: solid;
        border-image: linear-gradient(to right, #009AF8, #333AA1, #FFFFFF);
        border-image-slice: 3;
        position: absolute;
        bottom: 30%;
        left: 9.4%;
        background-color: rgba($color: #ffffff, $alpha: 0.16);
        display: flex;
        align-items: center;
        input {
          flex: 1;
          height: 98%;
          background-color: transparent;
          border: none;
          outline: none;
          color: #ffffff;
          padding-left: 23px;
          font-size: 16px;
          &::placeholder {
            color: #ffffff;
            font-size: 16px;
          }
        }
        svg {
          width: 16px;
          height: 16px;
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
    .pagination-box {
      margin: 20px auto 0px auto;
      text-align: right;
    }
    .application-use {
      height: 200px;
      background: url(../../assets/img/img_smallbanner.png) no-repeat center;
      background-size: cover;
      margin-top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        height: 40px;
        background-color: #ffffff;
        width: 128px;
        color: #3273f9;
        font-size: 14px;
        margin: 18px 0 0 86px;
        border: none;
        cursor: pointer;
      }
    }
  }
  @media screen and (min-width: 1366px) {
    .pagination-box {
      width: 91%;
    }
    .navigation {
      :deep(.center) {
        width: 91%;
      }
    }
  }
  @media screen and (min-width: 1920px) {
    .pagination-box {
      width: 73%;
    }
    .navigation {
      :deep(.center) {
        width: 73%;
      }
    }
  }
</style>
