<template>
  <div class="footer">
    <div class="logo">
      <img src="../../assets/img/logo.png" alt="">
    </div>
    <ul>
      <template v-for="item in navList" :key="item">
        <li :class="$route.meta.title === item.channelName ? 'active' : ''">
          <span @click="() => $router.push({path: item.path})">{{item.channelName}}</span>
        </li>
      </template>
    </ul>
    <p>Copyright © 2009-2023 思腾合力(天津)科技有限公司 All Rights Reserved <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">津ICP备19000271号-1</a></p>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  setup () {
    const navList = [
      {
        channelName: '软件产品',
        path: '/soft-support'
      },
      {
        channelName: '算法商城',
        path: '/algorithm-mall'
      },
      // {
      //   channelName: '技术支持',
      //   path: '/product'
      // },
      {
        channelName: '案例中心',
        path: ''
      }
    ]
    return {
      navList
    }
  }
})
</script>
<style lang="scss" scoped>
  .footer {
    height: 340px;
    background-color: #000000;
    margin-top: -1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      img {
        width: 123px;
        height: 123px;
      }
    }
    ul {
      display: flex;
      margin-top: 37px;
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        color: #ffffff;
        font-size: 14px;
        cursor: pointer;
        &:not(:first-child) {
          margin-left: 136px;
        }
        &.active {
          color: #3273f9;
        }
      }
    }
    p {
      color: #F1F1F1;
      font-size: 14px;
      margin-top: 89px;
    }
  }
</style>
