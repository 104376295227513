<template>
  <a-config-provider :locale="zhCN">
    <router-view/>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
dayjs.locale(zhCN.locale)
export default {
  setup () {
    return {
      zhCN
    }
  }
}
</script>
<style lang="scss">
  #app {
    height: 100%;
  }
</style>
