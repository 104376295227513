<template>
  <div class="doc-detail-container">
    <div class="banner">
      <head-navigation class="txd"></head-navigation>
      <div class="rack-top">
        <div class="header">
          技术支持>>{{ form.productName }}>> {{ form.productModel }}>><span
            >文档详情</span
          >
        </div>
        <div class="rack-top-detail">
          <div class="detail">
            <div class="label">{{ form.documentName }}</div>
            <div class="msg">
              <a-row>
                <a-col :span="6">
                  <div class="col">
                    <span class="col-title">文档编号:</span>
                    <span class="col-content">{{ form.documentCode }}</span>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="col">
                    <span class="col-title">更新时间:</span>
                    <span class="col-content">{{ form.updateTime }}</span>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="col">
                    <span class="col-title">文档密级:</span>
                    <span class="col-content">
                      <span v-if="form.documentLevel == 0"
                        >S级-仅限思腾工程师开放的通用案例</span
                      >
                      <span v-if="form.documentLevel == 1"
                        >A级-面向服务渠道开放的通用案例</span
                      >
                      <span v-if="form.documentLevel == 2"
                        >C级-面向购买产品的客户开放的通用案例</span
                      >
                      <span v-if="form.documentLevel == 3"
                        >D级-面向注册用户开放的通用案例</span
                      >
                      <span v-if="form.documentLevel == 4"
                        >E级-面向访客开放的通用案例</span
                      >
                    </span>
                  </div></a-col
                >
                <!-- <a-col :span="6"
                  ><div class="col">
                    <span class="col-title">有效时间:</span>
                    <span class="col-content">长期有效</span>
                  </div></a-col
                > -->
              </a-row>
              <a-row>
                <!-- <a-col :span="6"
                  ><div class="col">
                    <span class="col-title">浏览量:</span>
                    <span class="col-content">7950</span>
                  </div></a-col
                >
                <a-col
                  ><div class="col">
                    <span class="col-title">下载量:</span>
                    <span class="col-content">1234</span>
                  </div></a-col
                > -->
                <a-col>
                  <button class="download-btn" @click="handleDownload">
                    <svg-icon icon-class="ic_load"></svg-icon>下载文档
                  </button></a-col
                >
              </a-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs">
      <ul>
        <template v-for="(item, index) in tabList" :key="item.title">
          <li :class="activeIndex === index ? 'active' : 'default'">
            {{ item.title }}
          </li>
        </template>
      </ul>
      <!-- <div class="s-search-input" style="width: 20%">
        <input v-model="keyWord" placeholder="请输入关键词搜索" />
        <svg-icon icon-class="ic_search_grey" @click="handleSearch"></svg-icon>
      </div> -->
    </div>
    <div class="content">
      <div style="width: 100%; height: 100%">
        <VueOfficePdf :src="pdfUrl" style="width: 100%; height: 100%" />
      </div>

      <!-- <a-row justify="center" style="height: 100%">
        <a-col :span="5">
          <div
            :style="{ height: scrollHeight + 'px' }"
            class="left-navigate"
            :class="
              windowScrollTop >= windowBannerHeight && windowScrollTop !== 0
                ? 'active'
                : ''
            "
          >
            <div class="left-scroll">
              <ul class="scroll-item-child" v-for="item in docNavList" :key="item">
                <li
                  :class="currentDoc === item.id ? 'active' : ''"
                  @click="handleClick(item)"
                >
                  <a href="javascript:void(0)" @click="goAnchor(item.id)">{{
                    item.name
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </a-col>
        <a-col :span="14"><div class="docRight"></div></a-col>
        <a-col :span="5">
          <div
            class="right-navigate"
            :style="{ height: scrollHeight + 'px' }"
            :class="
              windowScrollTop >= windowBannerHeight && windowScrollTop !== 0
                ? 'active'
                : ''
            "
          >
            <div class="right-scroll">
              <div class="scroll-item-title">本页目录</div>
              <ul class="scroll-item-child">
                <li
                  :class="currentDoc === item.id ? 'active' : ''"
                  @click="handleClick(item)"
                  v-for="item in docDeatilNavList"
                  :key="item"
                >
                  <a href="javascript:void(0)" @click="goAnchor(item.id)">{{
                    item.name
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </a-col>
      </a-row> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import HeadNavigation from '@/components/HeadNavigation.vue'
import { useRouter, useRoute } from 'vue-router'
import { defineComponent, ref, onMounted, reactive } from 'vue'
import Footer from '@/components/footer/Footer'
import VueOfficePdf from '@vue-office/pdf'
export default defineComponent({
  components: {
    HeadNavigation,
    Footer,
    VueOfficePdf
  },
  setup () {
    const currentDoc = ref()
    const keyWord = ref()
    const pdfUrl = ref()
    const form = reactive({})
    const router = useRouter()
    const route = useRoute()
    const windowScrollTop = ref(0)
    const windowBannerHeight = ref(0)
    const scrollHeight = ref(
      window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    )
    const activeIndex = ref(0)
    const docNavList = ref([
      {
        name: '了解产品',
        id: '1',
        icon: 'ic_understandproduct'
      },
      {
        name: '快速系列',
        id: '2',
        icon: 'ic_fast'
      },
      {
        name: '安装升级',
        id: '3',
        icon: 'ic_upgradation'
      },
      {
        name: '配置调试',
        id: '4',
        icon: 'ic_configuration'
      },
      {
        name: '操作维护',
        id: '5',
        icon: 'ic_operate'
      },
      {
        name: '故障处理',
        id: '6',
        icon: 'ic_fault'
      },
      {
        name: '参考指南',
        id: '7',
        icon: 'ic_guide'
      },
      {
        name: '二次开发',
        id: '8',
        icon: 'ic_develop'
      },
      {
        name: '其他',
        id: '9',
        icon: 'ic_else'
      },
      {
        name: '文档合集',
        id: '10',
        icon: 'ic_all'
      },
      {
        name: '操作维护',
        id: '11',
        icon: 'ic_operate'
      },
      {
        name: '故障处理',
        id: '12',
        icon: 'ic_fault'
      },
      {
        name: '参考指南',
        id: '13',
        icon: 'ic_guide'
      },
      {
        name: '二次开发',
        id: '14',
        icon: 'ic_develop'
      },
      {
        name: '其他',
        id: '15',
        icon: 'ic_else'
      },
      {
        name: '文档合集',
        id: '16',
        icon: 'ic_all'
      }
    ])
    const docDeatilNavList = ref([
      {
        name: '了解产品',
        id: '1',
        icon: 'ic_understandproduct'
      },
      {
        name: '快速系列',
        id: '2',
        icon: 'ic_fast'
      },
      {
        name: '安装升级',
        id: '3',
        icon: 'ic_upgradation'
      },
      {
        name: '配置调试',
        id: '4',
        icon: 'ic_configuration'
      },
      {
        name: '操作维护',
        id: '5',
        icon: 'ic_operate'
      },
      {
        name: '故障处理',
        id: '6',
        icon: 'ic_fault'
      },
      {
        name: '参考指南',
        id: '7',
        icon: 'ic_guide'
      },
      {
        name: '二次开发',
        id: '8',
        icon: 'ic_develop'
      },
      {
        name: '其他',
        id: '9',
        icon: 'ic_else'
      },
      {
        name: '文档合集',
        id: '10',
        icon: 'ic_all'
      },
      {
        name: '操作维护',
        id: '11',
        icon: 'ic_operate'
      },
      {
        name: '故障处理',
        id: '12',
        icon: 'ic_fault'
      },
      {
        name: '参考指南',
        id: '13',
        icon: 'ic_guide'
      },
      {
        name: '二次开发',
        id: '14',
        icon: 'ic_develop'
      },
      {
        name: '其他',
        id: '15',
        icon: 'ic_else'
      },
      {
        name: '文档合集',
        id: '16',
        icon: 'ic_all'
      },
      {
        name: '操作维护',
        id: '17',
        icon: 'ic_operate'
      },
      {
        name: '故障处理',
        id: '18',
        icon: 'ic_fault'
      },
      {
        name: '参考指南',
        id: '19',
        icon: 'ic_guide'
      },
      {
        name: '二次开发',
        id: '20',
        icon: 'ic_develop'
      },
      {
        name: '其他',
        id: '21',
        icon: 'ic_else'
      },
      {
        name: '文档合集',
        id: '22',
        icon: 'ic_all'
      }
    ])
    onMounted(() => {
      if (route.query) {
        if (route.query.detail) {
          var data = JSON.parse(route.query.detail)
          for (var i in data) {
            form[i] = data[i]
          }
          pdfUrl.value = '/api/website' + data.picture
        }
      }
      // if (docNavList.value.length > 0) {
      //   currentDoc.value = docNavList.value[0].id;
      // }
      // // 监听滚动事件
      // window.addEventListener("scroll", onScroll);
    })
    // 移除监听器
    // onBeforeUnmount(() => {
    //   window.removeEventListener("scroll", onScroll);
    // });
    const tabList = ref([
      {
        id: '1',
        title: '文档详情'
      }
    ])
    // 滚动监听器
    function onScroll () {
      const bannerHeight = document.getElementsByClassName('banner')[0].offsetHeight + 64
      windowBannerHeight.value = bannerHeight
      // 获取所有锚点元素
      const navContents = document.querySelectorAll('.docRight ul')
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop + bannerHeight)
      })
      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      windowScrollTop.value = scrollTop
      // 定义当前点亮的导航下标
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n
        }
      }
      // 把下标赋值给 vue 的 data
      currentDoc.value = docNavList.value[navIndex].id

      // 判断若接近滚动至底部footer则计算左侧导航高度进行滚动显示
      const footerTop = document
        .getElementsByClassName('footer')[0]
        .getBoundingClientRect().top
      scrollHeight.value = footerTop
      document.getElementsByClassName('left-navigate')[0].style.overflowX = 'hidden'
      document.getElementsByClassName('left-navigate')[0].style.overflowY = 'auto'
      document.getElementsByClassName('right-navigate')[0].style.overflowX = 'hidden'
      document.getElementsByClassName('right-navigate')[0].style.overflowY = 'auto'
    }
    const handleSearch = () => {
      router.push({
        path: 'product-search',
        query: {
          keyword: keyWord.value,
          type: '1'
        }
      })
    }
    function handleDownload () {
      const iframe = document.createElement('iframe')
      iframe.src = `/api/website/picture/home/downloadFile?filePath=${form.picture}`
      iframe.style.display = 'none'
      document.body.appendChild(iframe)
      setTimeout(() => {
        document.body.removeChild(iframe)
      }, 1000)
    }
    return {
      tabList,
      activeIndex,
      currentDoc,
      windowScrollTop,
      windowBannerHeight,
      scrollHeight,
      docNavList,
      docDeatilNavList,
      handleSearch,
      pdfUrl,
      form,
      keyWord,
      handleDownload,
      onScroll
    }
  }
})
</script>

<style lang="scss" scoped>
.doc-detail-container {
  .download-btn {
    margin-left: 8px;
    border: unset;
    background: #ffffff;
    padding: 5px 13px;
    cursor: pointer;
    font-size: 14px;
    color: #3273f9;
    letter-spacing: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .banner {
    height: 30%;
    border: 1px solid #979797;
    background: #3273f9;
    min-height: 330px;
    .txd {
      position: relative;
    }
    .header {
      margin: 0 auto;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: 0;
      font-weight: 500;
      span {
        color: #ffffff;
      }
    }
    .rack-top {
      padding: 0 19%;
      margin: 0 auto;
      .rack-top-detail {
        // display: flex;
        .detail {
          margin-top: 24px;
          .label {
            background-image: linear-gradient(
              -86deg,
              rgba(50, 115, 249, 0) 0%,
              rgba(255, 255, 255, 0.45) 100%
            );
            font-size: 30px;
            color: #ffffff;
            letter-spacing: 0;
            font-weight: 700;
            padding: 7px 9px 7px 9px;
          }
          .msg {
            margin-top: 10px;
            font-size: 16px;
            color: #ffffff;
            font-weight: 400;
            line-height: 32px;
            .col {
              align-items: center;
              // line-height: 42px;
            }

            .col-title {
              font-size: 14px;
              color: #ffffff;
              letter-spacing: 0;
              text-align: right;
              font-weight: 400;
              width: 70px;
              float: left;
              align-items: center;
            }

            .col-content {
              font-size: 14px;
              color: #ffffff;
              font-weight: 700;
              text-align: justify;
              word-break: break-all;
              display: flow-root;
            }
          }
        }
      }
    }
  }
  .tabs {
    height: 64px;
    border: unset;
    box-shadow: 0 2px 27px 0 rgba(144, 161, 230, 0.5);
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
      float: left;
      width: 66%;

      li {
        height: 64px;
        line-height: 64px;
        margin: 0 5%;
        box-sizing: border-box;
        font-size: 14px;
        color: #474747;
        cursor: pointer;
      }

      li.deafult {
        font-weight: 200;
      }

      li.active {
        font-weight: 500;
        color: #3273f9;
      }
    }
  }
  .content {
    height: 100vh;
    background: #f8f8fd;
    padding: 0 19%;
    padding-top: 24px;
  }
  .left-navigate {
    height: 100%;
    min-width: 180px;
    top: 0;
    position: absolute;
    background: #eef3fe;
    width: 100%;
    border-right: 1px dashed #bbb6b6;
    &.active {
      position: fixed;
      width: calc(100% - 1665px);
    }
  }
  .left-scroll {
    width: 100%;
    overflow-y: auto;
    padding: 10px 0px 0 15px;
    ul {
      font-size: 16px;
      li {
        line-height: 26px;
        list-style-type: square;
        text-align: left;
        position: relative;
        cursor: pointer;
        color: #474747;
        a {
          text-decoration: none;
          background-color: none;
          color: #474747;
          &:hover {
            color: #0c5dff;
          }
        }
      }
      li::marker {
        font-size: 22px;
        color: #b8cefa;
      }
      li.active {
        color: #0c5dff;
        font-weight: 500;
        a {
          color: #0c5dff;
        }
      }
      li.active::marker {
        font-size: 22px;
        color: #0c5dff;
      }
    }
  }
  .right-navigate {
    height: 100%;
    min-width: 180px;
    top: 0;
    position: absolute;
    background: #eef3fe;
    width: 100%;
    border-left: 1px dashed #bbb6b6;
    &.active {
      position: fixed;
      width: calc(100% - 1665px);
    }
  }
  .right-scroll {
    width: 100%;
    overflow-y: auto;
    padding: 20px 0px 0 15px;
    .scroll-item-title {
      font-size: 16px;
      color: #474747;
      font-weight: 700;
      margin-left: 15%;
    }
    ul {
      font-size: 16px;
      list-style: none;
      li {
        line-height: 40px;
        // list-style-type: square;
        text-align: left;
        position: relative;
        cursor: pointer;
        color: #474747;
        a {
          text-decoration: none;
          background-color: none;
          color: #474747;
          &:hover {
            color: #0c5dff;
          }
        }
      }
      li::marker {
        font-size: 22px;
        color: #b8cefa;
      }
      li.active {
        color: #0c5dff;
        font-weight: 500;
        a {
          color: #0c5dff;
        }
      }
      li.active::marker {
        font-size: 22px;
        color: #0c5dff;
      }
    }
  }
}
</style>
