import { message } from 'ant-design-vue'
import axios from 'axios'
class Http {
  constructor () {
    this.baseUrl = '/api'
  }

  request (options) {
    const request = axios.create({
      baseURL: this.baseUrl
    })
    this.interceptors(request)
    return request(options)
  }

  interceptors (request) {
    request.interceptors.request.use(
      (config) => {
        return config
      },
      (error) => {
        console.log(error)
      }
    )
    request.interceptors.response.use(
      (res) => {
        const code = parseInt(res.data.code)
        if (code === 0) {
          return res.data
        } else if (code === -1) {
          message.error(res.data.msg)
          return Promise.reject(res.data)
        }
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }
}
export default new Http()
