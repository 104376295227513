import SvgIcon from '@/components/svg-icon/SvgIcon'
const req = require.context('./svg', false, /\.svg$/)
function requireAll (requireContext) {
  return requireContext.keys().map(requireContext)
}
requireAll(req)
export default {
  install: (Vue) => {
    Vue.component('SvgIcon', SvgIcon)
  }
}
