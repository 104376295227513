<template>
  <div class="navigation">
    <div class="center">
      <div class="logo">
        <img :src="logoSrc" alt="" @click="handleJumpIndex" />
      </div>
      <ul class="nav">
        <template v-for="item in navList" :key="item.channelName">
          <li :class="item.channelName === $route.meta.title ? 'active' : ''">
            <span @click="handleJump(item)">{{ item.channelName }}</span>
          </li>
        </template>
      </ul>
      <div class="consult">
        <button class="btn" @click="handleApply">申请试用</button>
      </div>
    </div>
    <ApplyTry v-model:open="open"/>
  </div>
</template>

<script>
import { ref, defineComponent } from 'vue'
import logoSrc from '@/assets/svg/img_logo_top.svg'
import { useRouter } from 'vue-router'
import ApplyTry from '@/components/apply-try/ApplyTry'
export default defineComponent({
  components: {
    ApplyTry
  },
  setup () {
    const router = useRouter()
    const navList = ref([
      {
        channelName: '软件产品',
        path: '/soft-support'
      },
      {
        channelName: '算法商城',
        path: '/algorithm-mall'
      },
      // {
      //   channelName: '技术支持',
      //   path: '/product'
      // },
      {
        channelName: '案例中心',
        path: ''
      }
    ])
    const handleJump = ({ path }) => {
      router.push({
        path: path
      })
    }
    const handleJumpIndex = () => {
      router.push({
        path: '/soft-support'
      })
    }
    const open = ref(false)
    const handleApply = () => {
      open.value = true
    }
    return {
      navList,
      logoSrc,
      handleJump,
      open,
      handleApply,
      handleJumpIndex
    }
  }
})
</script>

<style lang="scss" scoped>
.navigation {
  width: 100%;
  height: 95px;
  line-height: 95px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  .center {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      flex: 1;
      display: flex;
      img {
        cursor: pointer;
      }
    }
    .nav {
      flex: 2;
      white-space: nowrap;
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      // justify-content: space-between;
      justify-content: space-around;

      li {
        font-size: 16px;
        color: #ffffff;

        span {
          cursor: pointer;
        }
      }

      li.active {
        color: #3273f9;
        font-weight: 600;
      }

      li.default {
        color: #ffffff;
        text-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.6);
      }

      li.blackDefault {
        color: #474747;
      }
    }
  }
  .consult {
    margin-left: 10%;
    .btn {
      border: unset;
      background: #ffffff;
      padding: 10px 22px;
      cursor: pointer;
      font-size: 14px;
      color: #3273f9;
      letter-spacing: 0;
      font-weight: 500;
    }
  }
}
</style>
