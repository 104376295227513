<template>
  <div class="case" v-if="!blankVisible">
    <!-- <div class="check-box">
      <a-checkbox v-model:checked="checked">精品案例</a-checkbox>
    </div> -->
    <div class="table">
      <a-table
        :dataSource="dataSource"
        :columns="columns"
        bordered
        :pagination="false"
        size="small"
        :customRow="rowClick"
      >
        <template #headerCell="{ column }">
          <template v-if="column.key === 'scene'">
            <div class="header">
              应用场景
              <svg-icon icon-class="ic_filter" class="head-icon"></svg-icon>
            </div>
          </template>
          <template v-if="column.key === 'exampleLevel'">
            <div class="header">
              文档密级
              <a-popover title="立即登录 查看您的案例密级">
                <template #content>
                  <p>S级-仅限思腾工程师开放的通用案例</p>
                  <p>A级-面向服务渠道开放的通用案例</p>
                  <p>C级-面向购买产品的客户开放的通用案例</p>
                  <p>D级-面向注册用户开放的通用案例</p>
                  <p>E级-面向访客开放的通用案例</p>
                </template>
                <svg-icon icon-class="wenhao-grey" class="head-icon"></svg-icon>
              </a-popover>
            </div>
          </template>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'exampleLevel'">
            <span v-if="record.exampleLevel == 0">S级 </span
            ><span v-if="record.exampleLevel == 1">A级 </span
            ><span v-if="record.exampleLevel == 2">C级 </span
            ><span v-if="record.exampleLevel == 3">D级 </span
            ><span v-if="record.exampleLevel == 4">E级 </span>
          </template>
          <template v-if="column.key === 'caseName'">
            <div class="header">
              {{ record.caseName }}
              <svg-icon
                icon-class="ic_lock"
                class="head-icon"
                v-if="record.auth == 1"
              ></svg-icon>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <div class="pagination-box">
      <a-pagination
        :total="total"
        v-model:current="searchForm.pageNum"
        show-quick-jumper
        @change="handlePageSize"
        :showTotal="(total) => `共 ${total} 条`"
        size="small"
        show-size-changer
      ></a-pagination>
    </div>
  </div>
  <div class="blank" v-else>
    <div>
      <svg-icon icon-class="img_error_blank"></svg-icon>
      <div class="txt">暂无数据</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import http from '@/utils/http'
export default defineComponent({
  setup () {
    const route = useRoute()
    const router = useRouter()
    const checked = ref()
    const total = ref(0)
    const blankVisible = ref(false)
    const searchForm = reactive({
      pageNum: 1,
      pageSize: 10
    })
    const dataSource = ref([
      // {
      //   caseName: "demo",
      //   scene: "故障处理",
      //   fileLevel: "C",
      //   scanNum: 16,
      //   date: "2023-07-29",
      //   auth: 0,
      // },
      // {
      //   caseName: "demo1",
      //   scene: "故障处理",
      //   fileLevel: "C",
      //   scanNum: 9,
      //   date: "2023-06-30",
      //   auth: 1,
      // },
      // {
      //   caseName: "demo2",
      //   scene: "故障处理",
      //   fileLevel: "C",
      //   scanNum: 8,
      //   date: "2023-07-21",
      //   auth: 1,
      // },
      // {
      //   caseName: "demo3",
      //   scene: "故障处理",
      //   fileLevel: "C",
      //   scanNum: 12,
      //   date: "2023-06-03",
      //   auth: 1,
      // },
    ])
    const columns = ref([
      {
        title: '案例名称',
        dataIndex: 'exampleName',
        key: 'exampleName',
        scopedSlots: { customRender: 'exampleName' }
      },
      {
        title: '产品型号',
        dataIndex: 'productModels',
        key: 'productModels',
        scopedSlots: { customRender: 'productModels' }
      },
      {
        // title: '文档密级',
        dataIndex: 'exampleLevel',
        key: 'exampleLevel',
        scopedSlots: { customRender: 'exampleLevel' },
        ellipsis: true
      },
      // {
      //   title: "浏览量",
      //   dataIndex: "scanNum",
      //   key: "scanNum",
      //   scopedSlots: { customRender: "scanNum" },
      //   sorter: (a, b) => a.scanNum - b.scanNum,
      //   ellipsis: true,
      // },
      {
        title: '发布日期',
        dataIndex: 'publishTime',
        key: 'publishTime',
        scopedSlots: { customRender: 'publishTime' },
        sorter: (a, b) => a.publishTime - b.publishTime,
        ellipsis: true
      }
    ])
    onMounted(() => {
      getList()
    })
    const getList = () => {
      if (route.query) {
        if (route.query.productModelMsg) {
          var data = JSON.parse(route.query.productModelMsg)
          http
            .request({
              method: 'post',
              url: '/website/product/home/queryProductModelRelevance',
              data: {
                keyword: route.query.keyword,
                productModelId: data.id,
                type: 2,
                pageNum: searchForm.pageNum,
                pageSize: searchForm.pageSize
              }
            })
            .then((res) => {
              dataSource.value = res.data.rows
              total.value = res.data.total
              if (!dataSource.value) {
                blankVisible.value = true
              }
            })
        }
      }
    }
    const handleJump = () => {}
    const handlePageSize = (current, size) => {
      searchForm.pageNum = current
      searchForm.pageSize = size
      getList()
    }
    const rowClick = (record) => {
      return {
        onClick: (event) => {
          // if (record.auth === 0) {
          var data = JSON.parse(route.query.productModelMsg)
          const routeData = router.resolve({
            path: '/case-detail',
            query: {
              id: record.id,
              productName: data.productName,
              productModel: data.productModel
            }
          })
          window.open(routeData.href, '_blank')
          // router.push({
          //   path: "/case-detail",
          //   query: {
          //     id: record.id,
          //   },
          // });
          // }
        }
      }
    }
    return {
      checked,
      dataSource,
      columns,
      total,
      searchForm,
      handleJump,
      rowClick,
      blankVisible,
      handlePageSize
    }
  }
})
</script>

<style lang="scss" scoped>
.case {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 19% 0 19%;
  .check-box {
    padding-top: 22px;
  }
  .table {
    margin-top: 22px;
    flex: 1;
    .header {
      display: flex;
      align-items: center;
      .head-icon {
        width: 14px;
        height: 14px;
        margin-left: 6px;
      }
    }
  }
  .pagination-box {
    padding-bottom: 16px;
    padding-top: 16px;
    text-align: right;
  }
}
.blank {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  padding-bottom: 20%;
  .txt {
    font-size: 14px;
    color: #352f2f;
    text-align: center;
  }
  svg {
    height: 25rem;
    width: 25rem;
  }
}
</style>
