<template>
  <div class="container" v-if="!spinning">
    <div class="banner">
      <head-navigation></head-navigation>
      <!-- <div class="searchDiv">
        <div class="search">
          <input
            v-model="searchForm.keyword"
            placeholder="请输入产品序列号、产品型号、关键词"
          />
          <svg-icon icon-class="ic_search_grey" @click="handleSearch"></svg-icon>
        </div>
      </div> -->
    </div>
    <div class="content">
      <a-row style="width: 65%; margin-left: auto; margin-right: auto; min-width: 820px">
        <a-col :span="5">
          <div
            :style="{ height: scrollHeight + 'px' }"
            class="left-navigate"
            :class="
              windowScrollTop >= windowBannerHeight && windowScrollTop !== 0
                ? 'active'
                : ''
            "
          >
            <div class="left-scroll">
              <ul class="scroll-item-child" v-for="item in rackNavList" :key="item">
                <li
                  :class="currentRack === item.id ? 'active' : ''"
                  @click="handleClick(item)"
                >
                  <a href="javascript:void(0)" @click="goAnchor(item.id)">{{
                    item.productName
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </a-col>
        <a-col :span="19">
          <div class="right">
            <ul v-for="items in rackNavList" :key="items" :id="items.id">
              <li>
                <div class="title">{{ items.productName }}</div>
                <div class="seperate-border"></div>
                <div class="table">
                  <div class="table-item" v-for="vtem in items.models" :key="vtem">
                    <span @click="handleCheck(vtem, items.productName)">{{
                      vtem.productModel
                    }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </a-col>
      </a-row>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeadNavigation from '@/components/HeadNavigation.vue'
import { defineComponent, ref, onBeforeUnmount, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import Footer from '@/components/footer/Footer'
import http from '@/utils/http'
export default defineComponent({
  components: {
    HeadNavigation,
    Footer
  },
  setup () {
    const router = useRouter()
    const spinning = ref(true)
    const searchForm = reactive({
      keyword: ''
    })
    const keyWord = ref()
    const offsetWidth = ref()
    const currentRack = ref()
    const scrollHeight = ref(
      window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    )
    const windowScrollTop = ref(0)
    const windowBannerHeight = ref(0)
    const rackModelList = ref([
      '1288H V5',
      '2288  V5',
      '2288  V6',
      '2488  V5',
      '5288  V5',
      '5885H V6',
      'RH1288  V3',
      'RH5885H  V3',
      '5288  V7',
      '1288H V5',
      '2288  V5',
      '2288  V6',
      '2488  V5',
      '5288  V5',
      '5885H V6',
      'RH1288  V3',
      'RH5885H  V3',
      '5288  V7'
    ])
    const rackNavList = ref([
      // {
      //   name: "机架服务器",
      //   id: "1",
      // },
      // {
      //   name: "刀片服务器",
      //   id: "2",
      // },
      // {
      //   name: "高密服务器",
      //   id: "3",
      // },
      // {
      //   name: "关键业务服务器",
      //   id: "4",
      // },
      // {
      //   name: "GPU服务器",
      //   id: "5",
      // },
      // {
      //   name: "整机柜服务器",
      //   id: "6",
      // },
      // {
      //   name: "FusionDirector",
      //   id: "7",
      // },
      // {
      //   name: "iDriver",
      //   id: "8",
      // },
      // {
      //   name: "服务器管理",
      //   id: "9",
      // },
      // {
      //   name: "介质软件",
      //   id: "10",
      // },
      // {
      //   name: "管理生态",
      //   id: "11",
      // },
      // {
      //   name: "HPC&AI解决方案",
      //   id: "12",
      // },
      // {
      //   name: "分布式存储组件",
      //   id: "13",
      // },
      // {
      //   name: "数据库解决方案",
      //   id: "14",
      // },
      // {
      //   name: "超融合解决方案",
      //   id: "15",
      // },
      // {
      //   name: "FusionOS",
      //   id: "16",
      // },
    ])
    onMounted(() => {
      getList()
    })
    const getList = () => {
      http
        .request({
          method: 'post',
          url: '/website/product/home/queryProductList',
          data: searchForm
        })
        .then((res) => {
          rackNavList.value = res.data
          if (rackNavList.value.length > 0) {
            currentRack.value = rackNavList.value[0].id
          }
          // 监听滚动事件
          window.addEventListener('scroll', onScroll)
          spinning.value = false
        })
    }
    // 移除监听器
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onScroll)
    })
    // 滚动监听器
    function onScroll () {
      const bannerHeight = document.getElementsByClassName('banner')[0].offsetHeight - 50
      windowBannerHeight.value = bannerHeight
      // 获取所有锚点元素
      const navContents = document.querySelectorAll('.right ul')
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop + bannerHeight)
      })
      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      windowScrollTop.value = scrollTop
      // 定义当前点亮的导航下标
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n
        }
      }
      // 把下标赋值给 vue 的 data
      currentRack.value = rackNavList.value[navIndex].id

      // 判断若接近滚动至底部footer则计算左侧导航高度进行滚动显示
      const footerTop = document
        .getElementsByClassName('footer')[0]
        .getBoundingClientRect().top
      scrollHeight.value = footerTop
      document.getElementsByClassName('left-navigate')[0].style.overflowX = 'hidden'
      document.getElementsByClassName('left-navigate')[0].style.overflowY = 'auto'
    }
    const handleCheck = (item, productName) => {
      item.productName = productName
      router.push({
        path: '/product-detail',
        query: {
          productModelMsg: JSON.stringify(item)
        }
      })
    }
    const handleClick = (item) => {
      currentRack.value = item.id
    }
    const goAnchor = (id) => {
      var anchor = document.getElementById(id)
      anchor.scrollIntoView()
    }
    const handleSearch = () => {
      router.push({
        path: 'product-search',
        query: {
          keyword: searchForm.keyword,
          type: '0'
        }
      })
    }
    return {
      rackNavList,
      currentRack,
      handleClick,
      rackModelList,
      goAnchor,
      windowScrollTop,
      windowBannerHeight,
      handleCheck,
      scrollHeight,
      handleSearch,
      keyWord,
      searchForm,
      spinning,
      offsetWidth
    }
  }
})
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  .banner {
    background-size: cover;
    height: 50%;
    background: url("../../assets/img/img_productbanner.png") no-repeat center 100%;
    background-size: cover;
    display: flex;
    flex-direction: column-reverse;
    .searchDiv {
      align-items: center;
      justify-content: center;
      display: flex;
      .search {
        display: flex;
        position: relative;
        margin-top: -115px;
        width: 600px;
        border: 3px solid transparent;
        outline: none;
        border-image: linear-gradient(to right, #009af8, #d07ee1, #fff) 1;
        background: rgba(255, 255, 255, 0.16);
        height: 41px;
        svg {
          width: 14px;
          margin: 0px 8px;
          line-height: 48px;
          text-align: center;
          cursor: pointer;
        }
        input {
          flex: 1;
          width: 100%;
          border: 0;
          outline: none;
          word-spacing: normal;
          text-transform: none;
          text-indent: 0px;
          text-shadow: none;
          background-color: transparent;
          cursor: text;
          margin: 0em;
          overflow: hidden;
          margin-left: 2%;
          color: #fff;
          font-size: 16px;
        }
        ::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #c5c7cc;
        }
        ::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #c5c7cc;
        }

        ::-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #c5c7cc;
        }
      }
    }
  }

  .content {
    // height: 100%;
    width: 100%;
    padding: 2% 0% 0 0%;
    display: flex;
    // justify-content: center;
    overflow: hidden;
    .left-navigate {
      height: 100%;
      min-width: 200px;
      top: 0;
      position: absolute;
      background: #eef3fe;
      width: 100%;
      &.active {
        position: fixed;
        width: calc(100% - 1650px);
      }
    }
    .left-scroll {
      width: 100%;
      overflow-y: auto;
      padding: 10px 0px 0 15px;
      ul {
        font-size: 16px;
        li {
          line-height: 26px;
          list-style-type: square;
          text-align: left;
          position: relative;
          cursor: pointer;
          color: #474747;
          a {
            text-decoration: none;
            background-color: none;
            color: #474747;
            &:hover {
              color: #0c5dff;
            }
          }
        }
        li::marker {
          font-size: 22px;
          color: #b8cefa;
        }
        li.active {
          color: #0c5dff;
          font-weight: 500;
          a {
            color: #0c5dff;
          }
        }
        li.active::marker {
          font-size: 22px;
          color: #0c5dff;
        }
      }
    }
    .right {
      padding: 20px 0px 0 40px;
      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 58px;
      }
      .title {
        font-size: 14px;
        color: #474747;
        font-weight: 700;
      }
      .seperate-border {
        background-image: linear-gradient(90deg, #054bff 3%, rgba(230, 237, 255, 0) 100%);
        height: 2px;
        width: 100%;
        margin: 16px 0 16px 0;
      }
      .table {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 14px;
        .table-item {
          flex: 0 0 24%;
          height: 20px;
          // background-color: aqua;
          /* 边距用css函数代替 */
          margin-right: calc(4% / 3);
          margin-bottom: calc(4% / 3);
          span:hover {
            color: #0c5dff;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        /* 去除每行尾的多余边距 */
        .table-item:nth-child(4n) {
          margin-right: 0;
        }
        /* 使最后一个元素的边距填满剩余空间 */
        .table-item:last-child {
          margin-right: auto;
        }
        .table-item:nth-last-child(-n + 4) {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
