<template>
  <div class="soft-profile">
    <div class="table">
      <a-table
        :dataSource="dataSource"
        :columns="columns"
        bordered
        :pagination="false"
        size="small"
        :customRow="rowClick"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'version'">
            <div>
              {{ record.version }}
            </div>
            <div class="label">历史版本</div>
          </template>
          <template v-if="column.key === 'size'">
            <div class="header">
              <svg-icon icon-class="ic_load" class="head-icon"></svg-icon>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <div class="pagination-box">
      <a-pagination
        :total="total"
        v-model:current="searchForm.pageReq.pageNum"
        show-quick-jumper
        :showTotal="(total) => `共 ${total} 条`"
        size="small"
        show-size-changer
      ></a-pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from 'vue'
export default defineComponent({
  setup () {
    const total = ref(0)
    const searchForm = reactive({
      pageReq: {
        pageNum: 1,
        pageSize: 10
      }
    })
    const dataSource = ref([
      {
        category: '0',
        softName: 'FusionServer iDriver 3.0.114 版本说明书 01F',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        scene: '故障处理',
        fileLevel: 'C',
        size: 16,
        date: '2023-07-29',
        auth: 0,
        type: 'RAID',
        model: 'XU150-M',
        version: '5230.00-2578'
      },
      {
        softName: 'FusionServer iDriver 3.0.114 版本说明书 01F',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        scene: '故障处理',
        fileLevel: 'C',
        size: 9,
        date: '2023-06-30',
        auth: 1,
        type: 'RAID',
        model: 'XU150-M',
        category: '0',
        version: '5230.00-2578'
      },
      {
        softName: 'FusionServer iDriver 3.0.114 版本说明书 01F',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        scene: '故障处理',
        fileLevel: 'C',
        size: 8,
        date: '2023-07-21',
        auth: 1,
        type: 'RAID',
        model: 'XU150-M',
        category: '1',
        version: '5230.00-2578'
      },
      {
        softName: 'FusionServer iDriver 3.0.114 版本说明书 01F',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        scene: '故障处理',
        fileLevel: 'C',
        size: 12,
        date: '2023-06-03',
        auth: 1,
        type: 'RAID',
        model: 'XU150-M',
        category: '1',
        version: '5230.00-2578'
      }
    ])
    const columns = ref([
      {
        title: '文档名称',
        dataIndex: 'softName',
        key: 'softName',
        scopedSlots: { customRender: 'softName' }
      },
      {
        title: '文档密级',
        dataIndex: 'fileLevel',
        key: 'fileLevel',
        scopedSlots: { customRender: 'fileLevel' },
        width: 80
      },
      {
        title: '下载',
        dataIndex: 'size',
        key: 'size',
        scopedSlots: { customRender: 'size' },
        width: 80,
        align: 'center'
      },
      {
        title: '下载数字签名',
        dataIndex: 'sign',
        key: 'sign',
        scopedSlots: { customRender: 'sign' },
        ellipsis: true,
        width: 110
      }
    ])
    return {
      total,
      searchForm,
      dataSource,
      columns
    }
  }
})
</script>

<style lang="scss" scoped>
.soft-profile {
  .table {
    flex: 1;
    .header {
      justify-content: center;
    }
  }
  .pagination-box {
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: right;
  }
}
</style>
