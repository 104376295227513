<template>
  <div class="out-modal" @click="handleClose" v-if="open">
    <div class="modal-center" @click.stop="">
      <div class="modal-title">
        <span>申请试用</span>
      </div>
      <div class="modal-body">
        <a-form
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          :model="params"
          ref="form"
        >
          <a-form-item
            label="姓名"
            name="customName"
            :rules="[
              {
                required: true,
                message: '请输入姓名'
              }
            ]"
          >
            <a-input v-model:value="params.customName"></a-input>
          </a-form-item>
          <a-form-item
            label="联系电话"
            name="customPhone"
            :rules="[
              {
                required: true,
                message: '请输入联系电话'
              }
            ]"
          >
            <a-input v-model:value="params.customPhone"></a-input>
          </a-form-item>
          <a-form-item
            label="公司名称"
            name="compName"
            :rules="[
              {
                required: true,
                message: '请输入公司名称'
              }
            ]"
          >
            <a-input v-model:value="params.compName"></a-input>
          </a-form-item>
          <a-form-item
            label="职位"
            name="position"
            :rules="[
              {
                required: true,
                message: '请输入职位'
              }
            ]"
          >
            <a-input v-model:value="params.position"></a-input>
          </a-form-item>
          <a-form-item
            label="项目启动时间"
            name="firingTime"
            :rules="[
              {
                required: true,
                message: '请选择项目启动时间'
              }
            ]"
          >
            <a-date-picker
              v-model:value="params.firingTime"
              value-format="YYYY-MM-DD"
            />
          </a-form-item>
          <a-form-item
            label="需求描述"
            name="description"
            :rules="[
              {
                required: true,
                message: '请输入需求描述'
              }
            ]"
          >
            <a-textarea v-model:value="params.description"></a-textarea>
          </a-form-item>
          <a-form-item
            label="产品类型"
            name="productType"
            :rules="[
              {
                required: true,
                message: '请选择产品类型'
              }
            ]"
          >
            <a-radio-group v-model:value="params.productType">
              <a-radio :value="1">软件</a-radio>
              <a-radio :value="2">算法</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :wrapper-col="{ span: 16, offset: 5 }"
          >
            <a-checkbox v-model:checked="isAgree">
              <span>
                我已阅读并同意
                <a
                  style="color: #3273f9; font-weight: normal;"
                  href="https://docs.aiserver.cn/SitonCloud/privacy_policy/"
                  target="_blank"
                >
                  《隐私政策声明》
                </a>
              </span>
            </a-checkbox>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 19, offset: 3}">
            <button class="btn-primary" @click="handleSubmit">立即提交</button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
import { message } from 'ant-design-vue'
import { defineComponent, reactive, ref } from 'vue'
import http from '@/utils/http'

export default defineComponent({
  emits: ['update:open'],
  props: {
    open: {
      type: Boolean
    }
  },
  setup (_, { emit }) {
    const form = ref(null)
    const handleClose = () => {
      emit('update:open', false)
    }
    const params = reactive({
      compName: '',
      customName: '',
      customPhone: '',
      description: '',
      firingTime: '',
      position: '',
      productType: ''
    })
    const isAgree = ref(false)
    const handleSubmit = () => {
      form.value.validate().then(() => {
        if (!isAgree.value) {
          message.error('请勾选隐私政策声明')
        } else {
          http.request({
            method: 'post',
            url: '/website/trial/home/addTrialApply',
            data: params
          }).then(() => {
            message.success('申请成功', 0.5).then(() => {
              isAgree.value = false
              form.value.resetFields()
              emit('update:open', false)
            })
          })
        }
      })
    }
    return {
      handleClose,
      params,
      handleSubmit,
      form,
      isAgree
    }
  }
})
</script>
<style lang="scss" scoped>
  .out-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-center {
      width: 692px;
      height: 627px;
      background: #FFFFFF;
      box-shadow: 0 2px 10px 10px rgba(23,15,74,0.69);
      .modal-title {
        height: 64px;
        line-height: 64px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        background-color: #F0F5FF;
      }
      .modal-body {
        margin-top: 50px;
        .ant-picker {
          width: 100%;
        }
        .btn-primary {
          width: 100%;
          height: 40px;
        }
      }
    }
  }
</style>
