<template>
  <div class="application-container">
    <template v-for="(item, index) in algorithmData" :key="item.id">
      <div
        class="application-item"
        :style="{ background: `url(/api/website/${item.picture}) no-repeat center`}"
        @click="() => handleLink(item.id)"
        @mouseenter="() => handleMouseEnter(index)"
        @mouseleave="() => handleMouseLeave(index)"
      >
        <div class="content" :class="`content${index}`">
          <div class="title">
            <span>{{item.algorithmName}}</span>
          </div>
          <ul class="application-list">
            <template v-for="aItem in item.industryNameList" :key="aItem">
              <li>{{aItem}}</li>
            </template>
          </ul>
        </div>
        <div class="desc" :class="`desc${index}`" :style="getDescStyle(index)">
          <ul class="ul-title">
            <li>{{item.algorithmName}}</li>
            <li v-if="item.isPopular === 0">热门</li>
          </ul>
          <p>{{item.content}}</p>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { defineComponent, nextTick } from 'vue'
export default defineComponent({
  emits: ['link'],
  props: {
    algorithmData: {
      type: Array,
      required: true
    }
  },
  setup (_, { emit }) {
    const getDescStyle = (index) => {
      nextTick(() => {
        const desc = document.querySelector(`.desc${index}`)
        const height = desc.clientHeight
        desc.style.bottom = -(height + 32) + 'px'
      })
    }

    const handleMouseEnter = (index) => {
      const desc = document.querySelector(`.desc${index}`)
      desc.style.bottom = 0
      const content = document.querySelector(`.content${index}`)
      content.style.top = -(desc.clientHeight - 35) + 'px'
    }

    const handleMouseLeave = (index) => {
      const desc = document.querySelector(`.desc${index}`)
      desc.style.bottom = -desc.clientHeight + 'px'
      const content = document.querySelector(`.content${index}`)
      content.style.top = '26px'
    }

    const handleLink = (id) => {
      emit('link', id)
    }
    return {
      handleLink,
      getDescStyle,
      handleMouseEnter,
      handleMouseLeave
    }
  }
})
</script>
<style lang="scss" scoped>
  .application-container {
    margin: 14px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    .application-item {
      width: calc(25% - 12px);
      height: 480px;
      background-size: cover;
      margin-top: 16px;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      &:not(:nth-child(4n)) {
        margin-right: 16px;
      }
      .content {
        position: absolute;
        top: 26px;
        height: 434px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all linear 0.5s;
        .title {
          text-align: right;
          span {
            display: inline-block;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background-color: #3273f9;
            color: #ffffff;
            padding: 0 16px;
            font-size: 14px;
          }
        }
        .application-list {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          padding: 0;
          margin: 0;
          overflow: hidden;
          li {
            width: calc(33.3% - 13px);
            height: 28px;
            line-height: 28px;
            text-align: center;
            border: 2px solid #FFFFFF;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
            font-size: 14px;
            color: #ffffff;
            padding: 0 12px;
            box-sizing: border-box;
            margin-left: 10px;
            margin-top: 10px;
          }
        }
      }
      .desc {
        position: absolute;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.60);
        box-sizing: border-box;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        transition: all linear 0.5s;
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            color: #ffffff;
            font-size: 14px;
            &:last-child {
              background-color: #FF0000;
              height: 25px;
              line-height: 25px;
              padding: 0 10px;
            }
          }
        }
        p {
          font-size: 14px;
          color: #ffffff;
          margin-top: 20px;
        }
      }
    }
  }
  @media screen and (min-width: 1366px){
    .application-container {
      width: 91%;
    }
  }
  @media screen and (min-width: 1920px){
    .application-container {
      width: 73%;
    }
  }
</style>
