<template>
  <div class="soft-detail">
    <div class="banner">
      <head-navigation class="txd"></head-navigation>
      <div class="rack-top">
        <div class="header">
          技术支持>>机架服务器>> 1288H V5>><span>软件版本详情</span>
        </div>
        <div class="rack-top-detail">
          <div class="detail">
            <div class="label">服务器 安全信息05</div>
            <div class="msg">
              <a-row>
                <a-col :span="4">
                  <div class="col">
                    <span class="col-title" style="width: 50px">用途:</span>
                    <span class="col-content">商用</span>
                  </div>
                </a-col>
                <a-col :span="7">
                  <div class="col">
                    <span class="col-title">发布时间:</span>
                    <span class="col-content">2022-10-29 15:17:38</span>
                  </div>
                </a-col>
                <a-col :span="7">
                  <div class="col">
                    <span class="col-title">更新时间:</span>
                    <span class="col-content">2022-10-29 15:17:38</span>
                  </div></a-col
                >
                <a-col :span="6"
                  ><div class="col">
                    <span class="col-title">有效时间:</span>
                    <span class="col-content">长期有效</span>
                  </div></a-col
                >
              </a-row>
              <div class="version">
                <div>
                  <h1>软件完整性验证指南</h1>
                  超聚变数字技术有限公司为软件提供PGP和CMS格式的数字签名，分别用于人工和系统自动验证软件包的完整性。请您下载软件时同步下载数字签名，在软件使用前进行数字签名验证。
                </div>
                <div><span>人工签名验证：</span>使用PGPVerify或开源软件进行验证</div>
                <div>
                  <span>自动签名验证：</span
                  >与软件同步上传网管/部署工具，由网管/部署工具进行验证。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs">
      <ul>
        <template v-for="(item, index) in tabList" :key="item.title">
          <li
            :class="activeIndex === index ? 'active' : 'default'"
            @click="handleClick(index, item)"
          >
            {{ item.title }}
          </li>
        </template>
      </ul>
      <div class="s-search-input" style="width: 20%">
        <input v-model="keyWord" placeholder="请输入关键词搜索" />
        <svg-icon icon-class="ic_search_grey" @click="handleSearch"></svg-icon>
      </div>
    </div>
    <div class="content">
      <component :is="currentComponent"></component>
    </div>
      <Footer />
  </div>
</template>

<script>
import HeadNavigation from '@/components/HeadNavigation.vue'
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import SoftVersion from './SoftDetailVersion.vue'
import SoftProfile from './SoftDetailProfile.vue'
import Footer from '@/components/footer/Footer'
export default defineComponent({
  components: {
    HeadNavigation,
    SoftVersion,
    SoftProfile,
    Footer
  },
  setup () {
    const activeIndex = ref(0)
    const router = useRouter()
    const keyWord = ref()
    const currentComponent = ref('SoftVersion')
    const tabList = ref([
      {
        id: '1',
        title: '版本及补丁软件',
        path: 'SoftVersion'
      },
      {
        id: '2',
        title: '版本资料',
        path: 'SoftProfile'
      }
    ])
    const handleClick = (index, item) => {
      activeIndex.value = index
      currentComponent.value = item.path
    }
    const handleSearch = () => {
      router.push({
        path: 'product-search',
        query: {
          keyword: keyWord.value,
          type: '1'
        }
      })
    }
    return {
      activeIndex,
      tabList,
      handleClick,
      currentComponent,
      handleSearch,
      keyWord
    }
  }
})
</script>

<style lang="scss" scped>
.soft-detail {
  display: flex;
  flex-direction: column;
  .banner {
    height: 30%;
    border: 1px solid #979797;
    background: #3273f9;
    min-height: 330px;
    .txd {
      position: relative;
    }
    .header {
      margin: 0 auto;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: 0;
      font-weight: 500;
      span {
        color: #ffffff;
      }
    }
    .rack-top {
      padding: 0 19%;
      margin: 0 auto;
      .rack-top-detail {
        // display: flex;
        .detail {
          margin-top: 24px;
          .label {
            background-image: linear-gradient(
              -86deg,
              rgba(50, 115, 249, 0) 0%,
              rgba(255, 255, 255, 0.45) 100%
            );
            font-size: 30px;
            color: #ffffff;
            letter-spacing: 0;
            font-weight: 700;
            padding: 7px 9px 7px 9px;
          }
          .msg {
            margin-top: 10px;
            font-size: 16px;
            color: #ffffff;
            font-weight: 400;
            line-height: 32px;
            margin-bottom: 20px;
            .version {
              background: rgba(248, 248, 253, 0.16);
              border: 2px dashed #ffffff;
              font-size: 14px;
              color: #ffffff;
              font-weight: 400;
              margin-top: 20px;
              padding: 20px;
              h1 {
                font-size: 16px;
                font-weight: 700;
              }
              span {
                color: #ffffff;
                font-weight: 700;
              }
            }
            .col {
              align-items: center;
              // line-height: 42px;
            }

            .col-title {
              font-size: 14px;
              color: #ffffff;
              letter-spacing: 0;
              text-align: right;
              font-weight: 400;
              width: 70px;
              float: left;
              align-items: center;
            }

            .col-content {
              font-size: 14px;
              color: #ffffff;
              font-weight: 700;
              text-align: justify;
              word-break: break-all;
              display: flow-root;
            }
          }
        }
      }
    }
  }
  .tabs {
    height: 64px;
    border: unset;
    box-shadow: 0 2px 27px 0 rgba(144, 161, 230, 0.5);
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
      float: left;
      width: 46%;

      li {
        height: 64px;
        line-height: 64px;
        margin: 0 5%;
        box-sizing: border-box;
        font-size: 14px;
        color: #474747;
        cursor: pointer;
      }

      li.deafult {
        font-weight: 200;
      }

      li.active {
        font-weight: 700;
        font-size: 16px;
        border-bottom: 4px solid #3273f9;
      }
    }
  }
  .content {
    padding: 0 19%;
  }
  .search-select {
    background: #eef3fe;
    padding: 20px 30px;
    .soft-select-type {
      .soft-select-item {
        display: flex;
        align-items: center;
      }
      .soft-select-item-name {
        .name {
          font-size: 18px;
          color: #3273f9;
          text-align: center;
          font-weight: 700;
          margin-right: 56px;
        }
      }
      .soft-select-item-content {
        .all-check {
          margin-right: 10px;
        }
        .ant-checkbox-inner {
          display: none;
        }
        .ant-checkbox-checked {
          display: none;
        }
        .ant-checkbox-wrapper {
          padding: 6px 27px;
        }
        .ant-checkbox-wrapper-checked {
          background: #3273f9;
          border: 1px solid #3273f9;
          font-size: 14px;
          color: #ffffff;
          text-align: center;
          font-weight: 400;
        }
      }
    }
  }
  .table {
    flex: 1;
    margin-top: 16px;
    .header {
      display: flex;
      align-items: center;
      .head-icon {
        width: 14px;
        height: 14px;
        margin-left: 6px;
      }
    }
    .label {
      font-size: 14px;
      color: #3273f9;
      font-weight: 400;
    }
    .detail {
      font-size: 12px;
      color: #474747;
      font-weight: 400;
    }
  }
  .pagination-box {
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: right;
  }
}
</style>
