<template>
  <div class="container">
    <div class="banner">
      <head-navigation class="txd"></head-navigation>
      <div class="rack-top">
        <div class="header">
          技术支持>>{{ routeData.productName }}>>
          <span>{{ routeData.productModel }}</span>
        </div>
        <div class="rack-top-detail">
          <div class="pic">
            <a-image
              :width="200"
              :src="`/api/website` + serverForm.picture"
              v-if="serverForm.picture"
            />
          </div>
          <div class="detail">
            <div class="label">{{ routeData.productModel }}</div>
            <div class="msg">
              {{ serverForm.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs">
      <ul>
        <template v-for="(item, index) in tabList" :key="item.title">
          <li
            @click="handleClick(index, item)"
            :class="activeIndex === index ? 'active' : 'default'"
          >
            {{ item.title }}
          </li>
        </template>
      </ul>
      <!-- <div class="s-search-input">
        <input v-model="keyWord" placeholder="请输入关键词搜索" />
        <svg-icon icon-class="ic_search_grey" @click="handleSearch"></svg-icon>
      </div> -->
    </div>
    <div class="content">
      <component :is="currentComponent"></component>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeadNavigation from '@/components/HeadNavigation.vue'
import { defineComponent, ref, onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import rackPic from './img/racker.png'
import Document from './component/Document.vue'
import Case from './component/Case.vue'
import SoftWare from './component/SoftWare.vue'
import Announcement from './component/Announcement.vue'
import Footer from '@/components/footer/Footer'
import http from '@/utils/http'
export default defineComponent({
  components: {
    HeadNavigation,
    Document,
    Case,
    Footer,
    SoftWare,
    Announcement
  },
  setup () {
    const route = useRoute()
    const activeIndex = ref(0)
    const router = useRouter()
    const keyWord = ref()
    const routeData = reactive({})
    const serverForm = reactive({
      picture: '',
      content: ''
    })
    const currentComponent = ref('Document')
    const tabList = ref([
      {
        id: '1',
        title: '文档',
        path: 'Document'
      },
      {
        id: '2',
        title: '案例',
        path: 'Case'
      },
      // {
      //   id: "3",
      //   title: "软件",
      //   path: "SoftWare",
      // },
      {
        id: '4',
        title: '产品公告',
        path: 'Announcement'
      }
    ])
    onMounted(() => {
      if (route.query) {
        if (route.query.productModelMsg) {
          var data = JSON.parse(route.query.productModelMsg)
          for (var i in data) {
            routeData[i] = data[i]
          }
          getServer()
        }
      }
    })
    const getServer = () => {
      http
        .request({
          method: 'get',
          url: `/website/product/home/queryProductModelById/${routeData.id}`
        })
        .then((res) => {
          if (res) {
            for (var i in serverForm) {
              serverForm[i] = res.data[i]
            }
          }
        })
    }
    const handleClick = (index, item) => {
      activeIndex.value = index
      currentComponent.value = item.path
    }
    const handleSearch = () => {
      router.push({
        path: 'product-search',
        query: {
          keyword: keyWord.value,
          type: '1'
        }
      })
    }
    return {
      rackPic,
      tabList,
      activeIndex,
      handleClick,
      currentComponent,
      handleSearch,
      keyWord,
      routeData,
      serverForm
    }
  }
})
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .banner {
    border: 1px solid #979797;
    background: #3273f9;
    min-height: 410px;
    .txd {
      position: relative;
    }
    .header {
      margin: 0 auto;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: 0;
      font-weight: 500;
      span {
        color: #ffffff;
      }
    }
    .rack-top {
      padding: 0 19%;
      margin: 0 auto;
      .rack-top-detail {
        display: flex;
        .pic {
          height: 240px;
          width: 20%;
          background: #ffffff;
          box-shadow: 0 2px 12px 8px rgba(27, 86, 206, 0.3);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 24px;
        }
        .detail {
          margin-left: 40px;
          margin-top: 24px;
          .label {
            background-image: linear-gradient(
              -86deg,
              rgba(50, 115, 249, 0) 0%,
              rgba(255, 255, 255, 0.45) 100%
            );
            font-size: 30px;
            color: #ffffff;
            letter-spacing: 0;
            font-weight: 700;
            padding: 7px 9px 7px 9px;
          }
          .msg {
            margin-top: 32px;
            font-size: 16px;
            color: #ffffff;
            font-weight: 400;
          }
        }
      }
    }
  }
  .tabs {
    height: 64px;
    border: unset;
    box-shadow: 0 2px 27px 0 rgba(144, 161, 230, 0.5);
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
      float: left;
      width: 66%;

      li {
        height: 64px;
        line-height: 64px;
        margin: 0 5%;
        box-sizing: border-box;
        font-size: 14px;
        color: #474747;
        cursor: pointer;
      }

      li.deafult {
        font-weight: 200;
      }

      li.active {
        font-weight: 700;
        font-size: 16px;
        border-bottom: 4px solid #3273f9;
      }
    }
  }
  .s-search-input {
    float: right;
    width: 20%;
  }
  .content {
    // padding: 0 19%;
    // height: 100%;
    background: #f8f8fd;
  }
}
</style>
