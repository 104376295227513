import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  Carousel,
  Dropdown,
  Pagination,
  ConfigProvider,
  Col,
  Row,
  Image,
  Checkbox,
  Table,
  Popover,
  Form,
  Select,
  Tag,
  Modal,
  Input,
  DatePicker,
  Menu,
  Radio
} from 'ant-design-vue'
import Svg from '@/assets/icon/index'
import './styles/font/font.css'
import './styles/common.scss'

createApp(App)
  .use(store)
  .use(router)
  .use(Carousel)
  .use(Dropdown)
  .use(Pagination)
  .use(ConfigProvider)
  .use(Col)
  .use(Row)
  .use(Image)
  .use(Checkbox)
  .use(Table)
  .use(Popover)
  .use(Form)
  .use(Select)
  .use(Tag)
  .use(Modal)
  .use(Input)
  .use(DatePicker)
  .use(Menu)
  .use(Radio)
  .use(Svg).mount('#app')
