<template>
  <div class="announcement">
    <div class="table">
      <a-table
        :dataSource="dataSource"
        :columns="columns"
        :pagination="false"
        size="small"
        bordered
        :customRow="rowClick"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'noticeType'">
            <span v-if="record.noticeType == 0">生命周期管理</span>
          </template>
        </template>
      </a-table>
    </div>
    <div class="pagination-box">
      <a-pagination
        :total="total"
        @change="handlePageSize"
        v-model:current="searchForm.pageNum"
        show-quick-jumper
        :showTotal="(total) => `共 ${total} 条`"
        size="small"
        show-size-changer
      ></a-pagination>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import http from '@/utils/http'
export default defineComponent({
  setup () {
    const route = useRoute()
    const searchForm = reactive({
      pageNum: 1,
      pageSize: 10
    })
    const total = ref(0)
    const router = useRouter()
    const dataSource = ref([
      {
        category: '0',
        softName: 'FusionServer产品停止新签续保服务公告 V1.0',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        scene: '生命周期公告',
        fileLevel: 'C',
        size: 16,
        date: '2023-07-29',
        auth: 0,
        type: 'RAID',
        model: 'XU150-M',
        version: '5230.00-2578'
      },
      {
        softName: 'FusionServer产品停止新签续保服务公告 V1.0',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        scene: '生命周期公告',
        fileLevel: 'C',
        size: 9,
        date: '2023-06-30',
        auth: 1,
        type: 'RAID',
        model: 'XU150-M',
        category: '0',
        version: '5230.00-2578'
      },
      {
        softName: 'FusionServer产品停止新签续保服务公告 V1.0',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        scene: '生命周期公告',
        fileLevel: 'C',
        size: 8,
        date: '2023-07-21',
        auth: 1,
        type: 'RAID',
        model: 'XU150-M',
        category: '1',
        version: '5230.00-2578'
      },
      {
        softName: 'FusionServer产品停止新签续保服务公告 V1.0',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        scene: '生命周期公告',
        fileLevel: 'C',
        size: 12,
        date: '2023-06-03',
        auth: 1,
        type: 'RAID',
        model: 'XU150-M',
        category: '1',
        version: '5230.00-2578'
      }
    ])
    const columns = ref([
      {
        title: '公告名称',
        dataIndex: 'noticeName',
        key: 'noticeName',
        scopedSlots: { customRender: 'noticeName' }
      },
      {
        title: '公告类型',
        dataIndex: 'noticeType',
        key: 'noticeType',
        scopedSlots: { customRender: 'noticeType' }
      },
      {
        title: '发布日期',
        dataIndex: 'publishTime',
        key: 'publishTime',
        scopedSlots: { customRender: 'publishTime' },
        ellipsis: true
      }
    ])
    const rowClick = (record) => {
      return {
        onClick: (event) => {
          var data = JSON.parse(route.query.productModelMsg)
          const routeData = router.resolve({
            path: '/announcement-detail',
            query: {
              id: record.id,
              productName: data.productName,
              productModel: data.productModel
            }
          })
          window.open(routeData.href, '_blank')
        }
      }
    }
    onMounted(() => {
      getList()
    })
    const getList = () => {
      if (route.query) {
        if (route.query.productModelMsg) {
          var data = JSON.parse(route.query.productModelMsg)
          http
            .request({
              method: 'post',
              url: '/website/product/home/queryProductModelRelevance',
              data: {
                keyword: route.query.keyword,
                productModelId: data.id,
                type: 4,
                pageNum: searchForm.pageNum,
                pageSize: searchForm.pageSize
              }
            })
            .then((res) => {
              dataSource.value = res.data.rows
              total.value = res.data.total
            })
        }
      }
    }
    const handlePageSize = (current, size) => {
      searchForm.pageNum = current
      searchForm.pageSize = size
      getList()
    }
    return {
      dataSource,
      columns,
      searchForm,
      total,
      rowClick,
      handlePageSize
    }
  }
})
</script>

<style lang="scss" scoped>
.announcement {
  padding: 20px 19% 0 19%;
  .table {
    flex: 1;
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      .head-icon {
        width: 14px;
        height: 14px;
        margin-left: 6px;
      }
    }
    .label {
      font-size: 14px;
      color: #3273f9;
      font-weight: 400;
      cursor: pointer;
    }
    .detail {
      font-size: 12px;
      color: #474747;
      font-weight: 400;
    }
  }
  .pagination-box {
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: right;
  }
}
</style>
