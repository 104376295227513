<template>
  <div class="soft-version">
    <div class="search-select">
      <div class="soft-select-type">
        <div class="soft-select-item">
          <div class="soft-select-item-name">
            <span class="name">行业</span>
          </div>
          <div class="soft-select-item-content">
            <a-checkbox
              v-model:checked="state.checkAll"
              :indeterminate="state.indeterminate"
              @change="onCheckAllChange"
              class="all-check"
            >
              <span class="name">全部</span>
            </a-checkbox>
            <a-checkbox-group
              v-model:value="state.checkedList"
              :options="plainOptions"
              @change="onCheckSelect"
            />
          </div>
        </div>
        <div class="soft-select-item" v-for="item in checkList" :key="item" :class="{ active: !currentName.includes(item.name) }">
          <div class="soft-select-item-name">
            <span class="name">{{ item.name }}</span>
          </div>
          <div class="soft-select-item-select">
            <div class="soft-select-item-select-top">
              <section>
                <span
                  v-for="items in item.children"
                  :key="items"
                  class="select-top-item"
                  :class="{ active: currentIndex === items.name }"
                  @click="handleActive(items)"
                >
                  {{ items.name }}
                  <svg-icon
                    icon-class="ic_shang"
                    v-if="currentIndex === items.name && items.visible"
                  ></svg-icon>
                    <svg-icon
                    v-else
                    icon-class="ic_down"
                  ></svg-icon>
                </span>
              </section>
              <div
                class="soft-select-item-select-bottom"
                :class="{ active: currentIndex === items.name && items.visible }"
                v-for="items in item.children"
                :key="items"
              >
                <a-checkbox-group
                  v-model:value="state.checkedChildrenList"
                  :options="items.children"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table">
      <a-table
        :dataSource="dataSource"
        :columns="columns"
        :pagination="false"
        size="small"
      >
        <template #headerCell="{ column }">
          <template v-if="column.key === 'fileLevel'">
            <div class="header">
              软件密级
              <a-popover title="立即登录 查看您的案例密级">
                <template #content>
                  <p>S级-仅限思腾工程师开放的通用案例</p>
                  <p>A级-面向服务渠道开放的通用案例</p>
                  <p>C级-面向购买产品的客户开放的通用案例</p>
                  <p>D级-面向注册用户开放的通用案例</p>
                  <p>E级-面向访客开放的通用案例</p>
                </template>
                <svg-icon icon-class="wenhao-grey" class="head-icon"></svg-icon>
              </a-popover>
            </div>
          </template>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'softName'">
            <div class="label" @click="handleDetail">
              {{ record.softName }}
            </div>
            <div class="detail">
              {{ record.softDetail }}
            </div>
          </template>
          <template v-if="column.key === 'category'">
            <a-tag color="success" v-if="record.category == '0'">驱动</a-tag>
            <a-tag color="processing" v-if="record.category == '1'">固件</a-tag>
          </template>
          <template v-if="column.key === 'object'">
            <div class="detail">类型：{{ record.type }}</div>
            <div class="detail">型号：{{ record.model }}</div>
          </template>
          <template v-if="column.key === 'version'">
            <div>
              {{ record.version }}
            </div>
            <div class="label" @click="handleHistory">历史版本</div>
          </template>
          <template v-if="column.key === 'size'">
            <div class="header">
              {{ record.size }}
              <svg-icon icon-class="ic_load" class="head-icon"></svg-icon>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <div class="pagination-box">
      <a-pagination
        :total="total"
        v-model:current="searchForm.pageReq.pageNum"
        show-quick-jumper
        :showTotal="(total) => `共 ${total} 条`"
        size="small"
        show-size-changer
      ></a-pagination>
    </div>
    <a-modal
      v-model:visible="versionDetailVisible"
      title="软件详情"
      centered
      width="692px"
      :footer="null"
    >
      <div class="version-dialog-title">
        <a-tag color="processing">固件</a-tag>
        <div class="title">RAID-XU450C-M-3508-FW-5.230.00-3767.zip</div>
      </div>
      <div class="version-dialog-tips">
        固件文件,
        支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询 助手:
        查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)
      </div>
      <div class="version-dialog-content">
        <a-row>
          <a-col :span="12">
            <div class="col">
              <span class="col-title">所属版本：</span>
              <span class="col-content-primary">FusionServer iDriver 3.0.114</span>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="col">
              <span class="col-title">发布时间：</span>
              <span class="col-content">2023-07-06</span>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <div class="col">
              <span class="col-title">文档密级：</span>
              <span class="col-content">E级</span>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="col">
              <span class="col-title">文件大小：</span>
              <span class="col-content">10.49MB</span>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <div class="col">
              <span class="col-title">版本：</span>
              <span class="col-content">5.230.00-3767</span>
              <span class="col-content-primary" style="margin-left: 10px">历史版本</span>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <div class="col">
              <span class="col-title">适用对象：</span>
              <span class="col-content-default">板卡类型：</span>
              <span class="col-content-primary">RAID</span>
              <span class="col-content-default" style="margin-left: 16px"
                >板卡型号：</span
              >
              <span class="col-content-primary">XU450C-M</span>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="modal-footer">
        <button class="btn-m-cancel" @click="versionDetailVisible = false">取消</button>
        <button class="btn-m-download" style="margin-right: 20px">下载数字证书</button>
        <button class="btn-m-primary">下载软件</button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {},
  setup () {
    const router = useRouter()
    const versionDetailVisible = ref(false)
    const plainOptions = ['固件', '驱动']
    const currentName = ref(['固件', '驱动'])
    const state = reactive({
      indeterminate: true,
      checkAll: true,
      checkedList: [],
      checkedChildrenList: []
    })
    const total = ref(0)
    const searchForm = reactive({
      pageReq: {
        pageNum: 1,
        pageSize: 10
      }
    })
    const dataSource = ref([
      {
        category: '0',
        softName: 'RAID-XU450C-M-3508-FW-5.230.00-3767.zip',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        scene: '故障处理',
        fileLevel: 'C',
        size: 16,
        date: '2023-07-29',
        auth: 0,
        type: 'RAID',
        model: 'XU150-M',
        version: '5230.00-2578'
      },
      {
        softName: 'RAID-XU450C-M-3508-FW-5.230.00-3767.zip',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        scene: '故障处理',
        fileLevel: 'C',
        size: 9,
        date: '2023-06-30',
        auth: 1,
        type: 'RAID',
        model: 'XU150-M',
        category: '0',
        version: '5230.00-2578'
      },
      {
        softName: 'RAID-XU450C-M-3508-FW-5.230.00-3767.zip',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        scene: '故障处理',
        fileLevel: 'C',
        size: 8,
        date: '2023-07-21',
        auth: 1,
        type: 'RAID',
        model: 'XU150-M',
        category: '1',
        version: '5230.00-2578'
      },
      {
        softName: 'RAID-XU450C-M-3508-FW-5.230.00-3767.zip',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        scene: '故障处理',
        fileLevel: 'C',
        size: 12,
        date: '2023-06-03',
        auth: 1,
        type: 'RAID',
        model: 'XU150-M',
        category: '1',
        version: '5230.00-2578'
      }
    ])
    const columns = ref([
      {
        title: '类别',
        dataIndex: 'category',
        key: 'category',
        scopedSlots: { customRender: 'category' },
        align: 'center',
        width: 80
      },
      {
        title: '软件名称',
        dataIndex: 'softName',
        key: 'softName',
        scopedSlots: { customRender: 'softName' }
      },
      {
        title: '适用对象',
        dataIndex: 'object',
        key: 'object',
        scopedSlots: { customRender: 'object' }
      },
      {
        title: '版本',
        dataIndex: 'version',
        key: 'version',
        scopedSlots: { customRender: 'version' },
        width: 130
      },
      {
        title: '软件密级',
        dataIndex: 'fileLevel',
        key: 'fileLevel',
        scopedSlots: { customRender: 'fileLevel' },
        width: 100
      },
      {
        title: '文件大小',
        dataIndex: 'size',
        key: 'size',
        scopedSlots: { customRender: 'size' },
        width: 80,
        align: 'center'
      },
      {
        title: '下载数字签名',
        dataIndex: 'sign',
        key: 'sign',
        scopedSlots: { customRender: 'sign' },
        ellipsis: true,
        width: 110
      }
    ])
    const searchSelectList = ref([
      {
        title: '行业',
        children: [
          {
            title: '固件',
            key: '0'
          },
          {
            title: '驱动',
            key: '1'
          }
        ]
      }
    ])
    const currentIndex = ref()
    const checkList = ref([
      {
        children: [
          {
            children: [
              'MZ220',
              'QLE2694L',
              'LPe32000',
              'LPe32002',
              'LPe31000',
              'LPe31002',
              'LPe35000',
              'LPe35002',
              'QLE2560',
              'QLE2562',
              'QLE2690',
              'QLE2692',
              'QLE2740',
              'QLE2742',
              'QLE2770',
              'QLE2772',
              'SP520',
              'SP525',
              'SP521',
              'SP526',
              'SP522',
              'SP523'
            ],
            name: 'FC'
          },
          {
            children: [
              'MCX455A-ECAT',
              'MCX456A-ECAT',
              'MCX653105A-ECAT',
              'MCX653105A-EFAT',
              'MCX653105A-HDAT',
              'SP350',
              'SP351'
            ],
            name: 'IB'
          },
          {
            children: [
              'BCM957412A4120AC',
              'BCM957414A4142CC',
              'BCM957414N4140C',
              'BCM957504-P425G',
              'BCM957508-N2100G',
              'E810CQDA2G2P5',
              'E810XXVDA2G1P5',
              'E810XXVDA2OCP3G',
              'E810XXVDA4G2P5',
              'EXL710QDA2G1P5',
              'LOM-X722',
              'MCX4121A-ACAT',
              'MCX4121A-ACUT',
              'MCX4121A-XCAT',
              'MCX416A-BCAT',
              'MCX512A-ACAT',
              'MCX512A-ACUT',
              'MCX515A-CCAT',
              'MCX516A-CCAT',
              'MCX542B-ACAN',
              'MCX565M-CDAB',
              'MCX621102AN-ADAT',
              'MCX623106AN-CDAT',
              'MCX631102AN-ADAT',
              'MCX631432AN-ADAB',
              'MZ532',
              'MZ731',
              'SC332',
              'SC382',
              'SF200T',
              'SF400T',
              'SM233',
              'SM330',
              'SM380',
              'SP310',
              'SP330',
              'SP331',
              'SP332',
              'SP333',
              'SP380',
              'SP382',
              'SP572',
              'SP580',
              'SP582',
              'X710DA2OCPV3G1P',
              'X710T2LG1P5',
              'X710T2LOCPV3G1P',
              'XC310',
              'XC331',
              'XC333',
              'XC381',
              'XC382',
              'XC383',
              'XC385',
              'XC386',
              'XL710QSR1G1P5',
              'XP210',
              'XP212',
              'XP330',
              'XP332',
              'XP380',
              'XP382',
              'XXV710DA2G1P5'
            ],
            name: 'NIC'
          },
          {
            children: [
              '9305-24i',
              '9361-8i',
              '9440-8i',
              '9460-16i',
              '9460-8i',
              '9500-16e',
              '9500-16i',
              '9500-8e',
              '9500-8i',
              '9540-16i',
              '9540-8i',
              '9560-16i',
              '9560-8i',
              '9580-8i8e',
              'RU130',
              'RU150-M',
              'SmartRAID',
              'SP150-M',
              'SP450C-M',
              'SP460C-M',
              'SR130',
              'SR150-M',
              'SR230-M',
              'SR430C',
              'SR430C-M',
              'SR430C-ML',
              'SR450-M',
              'SR450C-M',
              'SR530C-M',
              'SR630C-M',
              'SR630C-ME',
              'SR760-M',
              'SR760IT-M',
              'XP150-M',
              'XP150IT-M',
              'XP450C-M',
              'XP460C-M',
              'XR150-M',
              'XR170-M-16i',
              'XR170-M-8i',
              'XR230-M',
              'XR370-M-16i',
              'XR370-M-8i',
              'XR450C-M',
              'XR450C-MX',
              'XR470C-M-16i',
              'XR470C-M-8i',
              'XR760-M',
              'XR760IT-M',
              'XU150-M',
              'XU450C-M',
              'XU470C-M-8i'
            ],
            name: 'RAID'
          }
        ],
        name: '固件',
        type: 'FW'
      },
      {
        children: [
          {
            children: ['AsianuxServer7.3', 'AsianuxServer7.6', 'AsianuxServer7.9'],
            name: 'Asianux Server'
          },
          {
            children: [
              'BClinux7.6',
              'BClinux7.7',
              'BClinux7.8',
              'BClinux8.1',
              'BClinux8.2',
              'BClinux8.4',
              'BClinux8.6'
            ],
            name: 'BC-Linux'
          },
          {
            children: [
              'CentOS7.3',
              'CentOS7.4',
              'CentOS7.5',
              'CentOS7.6',
              'CentOS7.7',
              'CentOS7.8',
              'CentOS7.9',
              'CentOS8.0',
              'CentOS8.1',
              'CentOS8.2',
              'CentOS8.3',
              'CentOS8.4',
              'CentOS8.5'
            ],
            name: 'CentOS'
          },
          {
            children: ['Citrix8.2'],
            name: 'Citrix'
          },
          {
            children: [
              'Debian10.0',
              'Debian10.8',
              'Debian11.0',
              'Debian11.5',
              'Debian9.6',
              'Debian9.8',
              'Debian9.9'
            ],
            name: 'Debian'
          },
          {
            children: ['FusionOS22', 'FusionOS23'],
            name: 'FusionOS'
          },
          {
            children: [
              'KylinLinuxV10',
              'KylinLinuxV10SP1',
              'KylinLinuxV10SP2',
              'KylinLinuxV10SP3'
            ],
            name: 'Kylin Linux Advanced Server'
          },
          {
            children: ['NeoKylinV7.4', 'NeoKylinV7.6'],
            name: 'NeoKylin'
          },
          {
            children: [
              'openEuler20.03',
              'openEuler20.03SP1',
              'openEuler20.03SP2',
              'openEuler20.03SP3',
              'openEuler22.03',
              'openEuler22.03SP1'
            ],
            name: 'openEuler'
          },
          {
            children: [
              'OEL7.3',
              'OEL7.4',
              'OEL7.5',
              'OEL7.6',
              'OEL7.7',
              'OEL7.8',
              'OEL7.9',
              'OEL8.0',
              'OEL8.1',
              'OEL8.2',
              'OEL8.3',
              'OEL8.4',
              'OEL8.5',
              'OEL8.6',
              'OEL9.0'
            ],
            name: 'Oracle Linux'
          },
          {
            children: ['OracleVM3.4.4', 'OracleVM3.4.5', 'OracleVM3.4.6'],
            name: 'OracleVM'
          },
          {
            children: [
              'RHEL7.3',
              'RHEL7.4',
              'RHEL7.5',
              'RHEL7.6',
              'RHEL7.7',
              'RHEL7.8',
              'RHEL7.9',
              'RHEL8.0',
              'RHEL8.1',
              'RHEL8.2',
              'RHEL8.3',
              'RHEL8.4',
              'RHEL8.5',
              'RHEL8.6',
              'RHEL8.7',
              'RHEL9.0',
              'RHEL9.1'
            ],
            name: 'RHEL'
          },
          {
            children: ['RockyLinux8.5', 'RockyLinux8.6', 'RockyLinux9.0'],
            name: 'RockyLinux'
          },
          {
            children: [
              'SLES12SP4',
              'SLES12SP5',
              'SLES15SP1',
              'SLES15SP2',
              'SLES15SP3',
              'SLES15SP4'
            ],
            name: 'SLES'
          },
          {
            children: [
              'Ubuntu16.04',
              'Ubuntu16.04.1',
              'Ubuntu16.04.5',
              'Ubuntu16.04.6',
              'Ubuntu18.04',
              'Ubuntu18.04.1',
              'Ubuntu18.04.5',
              'Ubuntu18.04.6',
              'Ubuntu20.04',
              'Ubuntu20.04.1',
              'Ubuntu20.04.5',
              'Ubuntu22.04',
              'Ubuntu22.04.1'
            ],
            name: 'Ubuntu'
          },
          {
            children: [
              'ESXi6.5.0',
              'ESXi6.5U1',
              'ESXi6.5U2',
              'ESXi6.5U3',
              'ESXi6.7.0',
              'ESXi6.7U1',
              'ESXi6.7U2',
              'ESXi6.7U3',
              'ESXi7.0.0',
              'ESXi7.0U1',
              'ESXi7.0U2',
              'ESXi7.0U3',
              'ESXi8.0.0',
              'ESXi8.0U1'
            ],
            name: 'VMware ESXi'
          },
          {
            children: ['Win2K12R2', 'Win2K16', 'Win2K19', 'Win2K22'],
            name: 'Windows Server'
          }
        ],
        name: '驱动',
        type: 'Driver'
      },
      {
        children: [],
        name: '自定义ISO',
        type: 'ISO'
      }
    ])
    const onCheckAllChange = (e) => {
      Object.assign(state, {
        checkedList: e.target.checked ? plainOptions : [],
        indeterminate: false
      })
      currentName.value = plainOptions
    }
    const onCheckSelect = (e) => {
      currentName.value = e
    }
    watch(
      () => state.checkedList,
      (val) => {
        state.indeterminate = !!val.length && val.length < plainOptions.length
        state.checkAll = val.length === plainOptions.length
      }
    )
    const handleDetail = () => {
      versionDetailVisible.value = true
    }
    const handleHistory = () => {
      const routeData = router.resolve({
        path: '/history-version'
      })
      window.open(routeData.href, '_blank')
    }
    const handleActive = (item) => {
      currentIndex.value = item.name
      item.visible = !item.visible
    }
    return {
      state,
      onCheckAllChange,
      plainOptions,
      dataSource,
      columns,
      searchForm,
      total,
      handleDetail,
      versionDetailVisible,
      handleHistory,
      searchSelectList,
      checkList,
      currentIndex,
      handleActive,
      onCheckSelect,
      currentName
    }
  }
})
</script>

<style lang="scss" scoped>
.soft-version {
  padding-top: 16px;
  .search-select {
    background: #eef3fe;
    padding: 20px 30px;
    .soft-select-type {
      .soft-select-item {
        // display: none;
        align-items: center;
        margin-bottom: 20px;
        &.active{
          display:none
        }
      }
      .soft-select-item-name {
        white-space: nowrap;
        .name {
          font-size: 18px;
          color: #3273f9;
          text-align: center;
          font-weight: 700;
          margin-right: 56px;
        }
      }
      .soft-select-item-content {
        .all-check {
          margin-right: 10px;
        }
        .ant-checkbox-inner {
          display: none;
        }
        .ant-checkbox-checked {
          display: none;
        }
        .ant-checkbox-wrapper {
          padding: 6px 27px;
        }
        .ant-checkbox-wrapper-checked {
          background: #3273f9;
          border: 1px solid #3273f9;
          font-size: 14px;
          color: #ffffff;
          text-align: center;
          font-weight: 400;
        }
      }
      .soft-select-item-select {
        position: relative;
        width: 90%;
        display: flex;
        .soft-select-item-select-top {
          position: relative;
          .select-top-item {
            font-size: 14px;
            color: #474747;
            font-weight: 400;
            margin-right: 16px;
            line-height: 32px;
            display: inline-block;
            cursor: pointer;
            svg {
              width: 8px;
              height: 8px;
              margin-left: 5px;
              &.active {
                transform: rotatex(180deg);
              }
            }
          }
        }
        .soft-select-item-select-bottom {
          margin-top: 10px;
          background: #fafcff;
          padding: 13px 17px;
          display: none;
          &.active {
            display: block;
          }
        }
      }
    }
  }
  .table {
    flex: 1;
    margin-top: 20px;
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      .head-icon {
        width: 14px;
        height: 14px;
        margin-left: 6px;
      }
    }
    .label {
      font-size: 14px;
      color: #3273f9;
      font-weight: 400;
      cursor: pointer;
    }
    .detail {
      font-size: 12px;
      color: #474747;
      font-weight: 400;
    }
  }
  .pagination-box {
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: right;
  }
}
.version-dialog-title {
  display: flex;
  align-items: center;
  margin-top: 20px;
  .title {
    font-size: 16px;
    color: #474747;
    font-weight: 700;
  }
}
.version-dialog-tips {
  margin-top: 13px;
  font-size: 14px;
  color: #474747;
  font-weight: 400;
  padding: 16px 20px;
  background: #f7f7f7;
}
.version-dialog-content {
  line-height: 35px;
  margin-top: 10px;
  .col {
    align-items: center;
    display: flex;
  }
  .col-title {
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    text-align: right;
    font-weight: 400;
    width: 70px;
    float: left;
    align-items: center;
  }
  .col-content {
    font-size: 14px;
    color: #000000;
    font-weight: 700;
    text-align: justify;
    word-break: break-all;
    display: flow-root;
  }
}
.col-content-primary {
  color: #3273f9;
  font-weight: 700;
}
.col-content-default {
  color: #c5c7cc;
  font-weight: 400;
}
</style>
