<template>
  <div class="history-version">
    <div class="banner">
      <head-navigation class="txd"></head-navigation>
      <div class="rack-top">
        <div class="header">
          技术支持>>机架服务器>> 1288H V5>>软件版本详情>><span>历史版本</span>
        </div>
        <div class="rack-top-detail">
          <div class="detail">
            <div class="label">历史版本</div>
            <div class="msg">
              <div class="version">
                <div>
                  <h1>软件完整性验证指南</h1>
                  超聚变数字技术有限公司为软件提供PGP和CMS格式的数字签名，分别用于人工和系统自动验证软件包的完整性。请您下载软件时同步下载数字签名，在软件使用前进行数字签名验证。
                </div>
                <div><span>人工签名验证：</span>使用PGPVerify或开源软件进行验证</div>
                <div>
                  <span>自动签名验证：</span
                  >与软件同步上传网管/部署工具，由网管/部署工具进行验证。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs">
      <ul>
        <li>当前版本：RAID-XU450C-M-3508-FW-5.230.00-3767.zip</li>
      </ul>
      <div class="s-search-input" style="width: 20%">
        <input placeholder="请输入软件名搜索" />
        <svg-icon icon-class="ic_search_grey"></svg-icon>
      </div>
    </div>
    <div class="content">
      <div class="alert">
        <svg-icon icon-class="ic_tips" class="head-icon"></svg-icon>
        <div>当前仅展现了部分资源，请<span>登录</span>后查看更多资源</div>
      </div>
      <div class="tips">
        当前版本：<span>RAID-XU450C-M-3508-FW-5.230.00-3767.zip</span>
      </div>
      <div class="table">
        <a-table
          :dataSource="dataSource"
          :columns="columns"
          :pagination="false"
          size="small"
          @resizeColumn="handleResizeColumn"
        >
          <template #headerCell="{ column }">
            <template v-if="column.key === 'fileLevel'">
              <div class="header">
                软件密级
                <a-popover title="立即登录 查看您的案例密级">
                  <template #content>
                    <p>S级-仅限思腾工程师开放的通用案例</p>
                    <p>A级-面向服务渠道开放的通用案例</p>
                    <p>C级-面向购买产品的客户开放的通用案例</p>
                    <p>D级-面向注册用户开放的通用案例</p>
                    <p>E级-面向访客开放的通用案例</p>
                  </template>
                  <svg-icon icon-class="wenhao-grey" class="head-icon"></svg-icon>
                </a-popover>
              </div>
            </template>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'softName'">
              <div class="label" @click="handleDetail">
                {{ record.softName }}
              </div>
              <div class="detail">
                {{ record.softDetail }}
              </div>
            </template>
            <template v-if="column.key === 'category'">
              <a-tag color="success" v-if="record.category == '0'">驱动</a-tag>
              <a-tag color="processing" v-if="record.category == '1'">固件</a-tag>
            </template>
            <template v-if="column.key === 'object'">
              <div class="label">{{ record.model }}</div>
              <div>发布时间：{{ record.date }}</div>
            </template>
            <template v-if="column.key === 'size'">
              <div class="header">
                {{ record.size }}
                <svg-icon icon-class="ic_load" class="head-icon"></svg-icon>
              </div>
            </template>
          </template>
        </a-table>
      </div>
      <div class="pagination-box">
        <a-pagination
          :total="total"
          v-model:current="searchForm.pageReq.pageNum"
          show-quick-jumper
          :showTotal="(total) => `共 ${total} 条`"
          size="small"
          show-size-changer
        ></a-pagination>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeadNavigation from '@/components/HeadNavigation.vue'
import { defineComponent, ref, reactive } from 'vue'
import Footer from '@/components/footer/Footer'
export default defineComponent({
  components: {
    HeadNavigation,
    Footer
  },
  setup () {
    const total = ref(0)
    const searchForm = reactive({
      pageReq: {
        pageNum: 1,
        pageSize: 10
      }
    })
    const dataSource = ref([
      {
        category: '0',
        softName: 'RAID-XU450C-M-3508-FW-5.230.00-3767.zip',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        use: '商用',
        fileLevel: 'C',
        size: 16,
        date: '2023-07-29',
        auth: 0,
        type: 'RAID',
        model: 'FusionServer iDriver 3.0.108',
        version: '5230.00-2578'
      },
      {
        softName: 'RAID-XU450C-M-3508-FW-5.230.00-3767.zip',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        use: '商用',
        fileLevel: 'C',
        size: 9,
        date: '2023-06-30',
        auth: 1,
        type: 'RAID',
        model: 'FusionServer iDriver 3.0.108',
        category: '0',
        version: '5230.00-2578'
      },
      {
        softName: 'RAID-XU450C-M-3508-FW-5.230.00-3767.zip',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        use: '商用',
        fileLevel: 'C',
        size: 8,
        date: '2023-07-21',
        auth: 1,
        type: 'RAID',
        model: 'FusionServer iDriver 3.0.108',
        category: '1',
        version: '5230.00-2578'
      },
      {
        softName: 'RAID-XU450C-M-3508-FW-5.230.00-3767.zip',
        softDetail:
          '固件文件, 支持使用工具SmartProvisioning-1.2.0.5及其以上版本进行升级(请先使用兼容性查询助手: 查询部件与OS兼容性,然后下载驱动配套表查询驱动和固件配套版本)',
        use: '商用',
        fileLevel: 'C',
        size: 12,
        date: '2023-06-03',
        auth: 1,
        type: 'RAID',
        model: 'FusionServer iDriver 3.0.108',
        category: '1',
        version: '5230.00-2578'
      }
    ])
    const columns = ref([
      {
        title: '版本',
        dataIndex: 'object',
        key: 'object',
        scopedSlots: { customRender: 'object' },
        ellipsis: true,
        width: 180,
        resizable: true
      },
      {
        title: '用途',
        dataIndex: 'use',
        key: 'use',
        scopedSlots: { customRender: 'use' },
        align: 'center',
        width: 80,
        ellipsis: true,
        resizable: true
      },
      {
        title: '软件名称',
        dataIndex: 'softName',
        key: 'softName',
        scopedSlots: { customRender: 'softName' }
      },
      {
        title: '历史版本',
        dataIndex: 'version',
        key: 'version',
        scopedSlots: { customRender: 'version' },
        width: 120,
        ellipsis: true,
        resizable: true
      },
      {
        title: '发布时间',
        dataIndex: 'date',
        key: 'date',
        scopedSlots: { customRender: 'date' },
        width: 100,
        ellipsis: true,
        resizable: true
      },
      {
        title: '文件大小',
        dataIndex: 'size',
        key: 'size',
        scopedSlots: { customRender: 'size' },
        ellipsis: true,
        width: 80,
        align: 'center',
        resizable: true
      },
      {
        title: '下载数字签名',
        dataIndex: 'sign',
        key: 'sign',
        scopedSlots: { customRender: 'sign' },
        ellipsis: true,
        width: 110,
        resizable: true
      }
    ])
    function handleResizeColumn (w, col) {
      col.width = w
    }
    return {
      dataSource,
      columns,
      searchForm,
      total,
      handleResizeColumn
    }
  }
})
</script>

<style lang="scss" scped>
.history-version {
  display: flex;
  flex-direction: column;
  .banner {
    height: 30%;
    border: 1px solid #979797;
    background: #3273f9;
    min-height: 330px;
    .txd {
      position: relative;
    }
    .header {
      margin: 0 auto;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: 0;
      font-weight: 500;
      span {
        color: #ffffff;
      }
    }
    .rack-top {
      padding: 0 19%;
      margin: 0 auto;
      .rack-top-detail {
        // display: flex;
        .detail {
          margin-top: 24px;
          .label {
            background-image: linear-gradient(
              -86deg,
              rgba(50, 115, 249, 0) 0%,
              rgba(255, 255, 255, 0.45) 100%
            );
            font-size: 30px;
            color: #ffffff;
            letter-spacing: 0;
            font-weight: 700;
            padding: 25px 9px 25px 9px;
            text-align: center;
          }
          .msg {
            margin-top: 10px;
            font-size: 16px;
            color: #ffffff;
            font-weight: 400;
            line-height: 32px;
            margin-bottom: 20px;
            .version {
              background: rgba(248, 248, 253, 0.16);
              border: 2px dashed #ffffff;
              font-size: 14px;
              color: #ffffff;
              font-weight: 400;
              margin-top: 20px;
              padding: 20px;
              h1 {
                font-size: 16px;
                font-weight: 700;
              }
              span {
                color: #ffffff;
                font-weight: 700;
              }
            }
            .col {
              align-items: center;
              // line-height: 42px;
            }

            .col-title {
              font-size: 14px;
              color: #ffffff;
              letter-spacing: 0;
              text-align: right;
              font-weight: 400;
              width: 70px;
              float: left;
              align-items: center;
            }

            .col-content {
              font-size: 14px;
              color: #ffffff;
              font-weight: 700;
              text-align: justify;
              word-break: break-all;
              display: flow-root;
            }
          }
        }
      }
    }
  }
  .tabs {
    height: 64px;
    border: unset;
    box-shadow: 0 2px 27px 0 rgba(144, 161, 230, 0.5);
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      height: 100%;
      display: flex;
      align-items: center;
      white-space: nowrap;
      float: left;
      width: 46%;

      li {
        height: 64px;
        line-height: 64px;
        margin: 0 5%;
        box-sizing: border-box;
        font-size: 14px;
        color: #474747;
        cursor: pointer;
      }

      li.deafult {
        font-weight: 200;
      }

      li.active {
        font-weight: 700;
        font-size: 16px;
        border-bottom: 4px solid #3273f9;
      }
    }
  }
  .content {
    padding: 0 19%;
    .alert {
      margin-top: 20px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #e05e13;
      font-weight: 400;
      span {
        color: #3273f9;
        font-weight: 700;
      }
      svg {
        margin-right: 10px;
        margin-top: -3px;
      }
    }
    .tips {
      margin-top: 18px;
      background: #f1f6ff;
      border: 1px solid #3273f9;
      font-size: 14px;
      color: #474747;
      font-weight: 400;
      padding: 14px;
      span {
        color: #3273f9;
      }
    }
  }
  .head-icon {
    width: 14px;
    height: 14px;
    margin-left: 6px;
  }
  .table {
    flex: 1;
    // margin-top: 16px;
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .label {
      font-size: 14px;
      color: #3273f9;
      font-weight: 400;
    }
    .detail {
      font-size: 12px;
      color: #474747;
      font-weight: 400;
    }
  }
  .pagination-box {
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: right;
  }
}
</style>
