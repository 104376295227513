<template>
  <div class="filter-condition">
    <div class="item industry-item">
      <h4>行业</h4>
      <ul>
        <li
          :class="industryIds.length === 0 ? 'active' : ''"
          @click="() => handleChangeIndustry('all')"
        >
          全部
        </li>
        <template v-for="item in industryDict" :key="item.id">
          <li
            :class="(industryIds.includes(item.id) && industryIds.length !== industryDict.length) ? 'active' : ''"
            @click="() => handleChangeIndustry(item.id)"
          >
            {{item.industryName}}
            <svg-icon
              icon-class="ic_cha"
              v-if="industryIds.includes(item.id) && industryIds.length !== 0"
              @click.stop="() => handleRemoveIndustryIds(item.id)"
            />
          </li>
        </template>
      </ul>
      <div class="more">
        <span class="span-more" @click="handleShowMoreIndustry">
          更多
          <svg-icon icon-class="you"></svg-icon>
        </span>
      </div>
    </div>
    <div class="item scene-item">
      <h4>场景</h4>
      <ul>
        <li
          :class="sceneIds.length === 0 ? 'active' : ''"
          @click="() => handleChangeScene('all')"
        >
          全部
        </li>
        <template v-for="item in sceneDict" :key="item.id">
          <li
            :class="(sceneIds.includes(item.id) && sceneIds.length !== sceneDict.length) ? 'active' : ''"
            @click="() => handleChangeScene(item.id)"
          >
            {{item.sceneName}}
            <svg-icon
              icon-class="ic_cha"
              v-if="sceneIds.includes(item.id) && sceneIds.length !== 0"
              @click.stop="() => handleRemoveSceneIds(item.id)"
            />
          </li>
        </template>
      </ul>
      <div class="more">
        <span class="span-more" @click="handleShowMore">
          更多
          <svg-icon icon-class="you"></svg-icon>
        </span>
      </div>
    </div>
    <div class="item">
      <h4>其他</h4>
      <ul>
        <li
          :class="other === 0 ? 'active' : ''"
          @click="() => handleChangeOther(0)"
        >
          图片类
        </li>
        <li
          :class="other === 1 ? 'active' : ''"
          @click="() => handleChangeOther(1)"
        >
          视频类
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
  emits: ['setIndustryIds', 'setSceneIds', 'setOther', 'showMore', 'removeIndustryIds', 'removeSceneIds', 'showMoreIndustry'],
  props: {
    sceneDict: {
      type: Array,
      required: true
    },
    industryDict: {
      type: Array,
      required: true
    },
    industryIds: {
      type: Array,
      required: true
    },
    other: {
      type: Number,
      required: true
    },
    sceneIds: {
      type: Array,
      required: true
    }
  },
  setup (props, { emit }) {
    const handleChangeIndustry = (type) => {
      if (type === 'all') {
        emit('setIndustryIds', '')
      } else {
        emit('setIndustryIds', type)
      }
    }

    watch(props.industryIds, () => {
      if (props.industryIds.length === props.industryDict.length) {
        emit('setIndustryIds', '')
      }
    })

    const handleRemoveIndustryIds = (type) => {
      emit('removeIndustryIds', type)
    }

    const handleChangeScene = (type) => {
      if (type === 'all') {
        emit('setSceneIds', '')
      } else {
        emit('setSceneIds', type)
      }
    }

    const handleRemoveSceneIds = (type) => {
      emit('removeSceneIds', type)
    }

    watch(props.sceneIds, () => {
      if (props.sceneIds.length === props.sceneDict.length) {
        emit('setSceneIds', '')
      }
    })

    const handleChangeOther = (type) => {
      emit('setOther', type)
    }

    const isMore = ref(false)
    const handleShowMore = () => {
      isMore.value = !isMore.value
      emit('showMore', isMore.value)
    }

    const isMoreIndustry = ref(false)
    const handleShowMoreIndustry = () => {
      isMoreIndustry.value = !isMoreIndustry.value
      emit('showMoreIndustry', isMoreIndustry.value)
    }

    return {
      handleChangeIndustry,
      handleChangeScene,
      handleChangeOther,
      isMore,
      handleShowMore,
      handleRemoveIndustryIds,
      handleRemoveSceneIds,
      handleShowMoreIndustry,
      isMoreIndustry
    }
  }
})
</script>
<style lang="scss" scoped>
  .filter-condition {
    margin: 14px auto 0 auto;
    background-color: #EEF3FE;
    padding: 1px 32px 20px 30px;
    box-sizing: border-box;
    .item {
      display: flex;
      align-items: center;
      h4 {
        color: #3273f9;
        font-size: 18px;
        font-weight: 700;
      }
      ul {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 36px;
        li {
          padding: 0 10px;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          color: #474747;
          text-align: center;
          min-width: 56px;
          cursor: pointer;
          &:not(:first-child) {
            margin-left: 12px;
          }
          &.active {
            background-color: #3273f9;
            color: #ffffff;
          }
          svg {
            width: 8px;
            height: 8px;
            margin-left: 6px;
          }
        }
      }
      .more {
        user-select: none;
        .span-more {
          color: #3273f9;
          font-size: 14px;
          svg {
            width: 6px;
            height: 6px;
          }
        }
      }
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
    .industry-item {
      align-items: flex-start;
      h4 {
        margin-top: 19px;
      }
      ul {
        flex-wrap: wrap;
        li {
          margin-top: 16px;
        }
      }
      .more {
        cursor: pointer;
        margin-top: 19px;
      }
    }
    .scene-item {
      align-items: flex-start;
      margin-top: 0px !important;
      h4 {
        margin-top: 19px;
      }
      ul {
        flex-wrap: wrap;
        li {
          margin-top: 16px;
        }
      }
      .more {
        margin-top: 19px;
        cursor: pointer;
      }
    }
  }
  @media screen and (min-width: 1366px){
    .filter-condition {
      width: 91%;
    }
  }
  @media screen and (min-width: 1920px){
    .filter-condition {
      width: 73%;
    }
  }
</style>
