<template>
  <div class="doc">
    <a-row
      style="
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        width: 62%;
        min-width: 780px;
      "
    >
      <a-col :span="5" v-if="docNavList">
        <div
          :style="{ height: scrollHeight + 'px' }"
          class="left-navigate"
          :class="
            windowScrollTop >= windowBannerHeight && windowScrollTop !== 0 ? 'active' : ''
          "
        >
          <div class="left-scroll">
            <ul class="scroll-item-child" v-for="item in docNavList" :key="item">
              <li
                :class="currentDoc === item.id ? 'active' : ''"
                @click="handleClick(item)"
              >
                <a href="javascript:void(0)" @click="goAnchor(item.id)">{{
                  item.typeName
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </a-col>
      <a-col :span="19">
        <div class="docRight">
          <ul v-for="items in docNavList" :key="items" :id="items.id">
            <li>
              <div class="title">
                <svg-icon :icon-class="items.icon" class="title-svg"></svg-icon>
                <div class="txt">{{ items.typeName }}</div>
              </div>
              <div class="table">
                <div class="table-item" v-for="stem in items.documentList" :key="stem">
                  <div class="label">
                    <div>{{ stem.typeName }}</div>
                    <svg-icon
                      icon-class="wenhao-grey"
                      style="width: 13px; height: 13px"
                    ></svg-icon>
                  </div>
                  <div class="item">
                    <a-row justify="space-between">
                      <a-col>
                        <span class="item-title" @click="handleJump(stem)">{{
                          stem.documentName
                        }}</span>
                        <svg-icon
                          icon-class="ic_load"
                          class="label-svg"
                          @click="handleDownload(stem)"
                        ></svg-icon>
                        <!-- <svg-icon
                          icon-class="ic_rank_sheng_backup"
                          class="label-svg"
                          style="width: 13px; height: 13px"
                        ></svg-icon> -->
                        <!-- <svg-icon icon-class="ic_lock" class="label-svg"></svg-icon> -->
                      </a-col>
                      <a-col>
                        <span class="label">{{ stem.updateTime }}</span>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import http from '@/utils/http'
export default defineComponent({
  setup () {
    const route = useRoute()
    const router = useRouter()
    const currentDoc = ref()
    const windowScrollTop = ref(0)
    const windowBannerHeight = ref(0)
    const scrollHeight = ref(
      window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
    )
    const docIconList = ref([
      {
        name: '了解产品',
        id: '1',
        icon: 'ic_understandproduct'
      },
      {
        name: '快速系列',
        id: '2',
        icon: 'ic_fast'
      },
      {
        name: '安装升级',
        id: '3',
        icon: 'ic_upgradation'
      },
      {
        name: '配置调试',
        id: '4',
        icon: 'ic_configuration'
      },
      {
        name: '操作维护',
        id: '5',
        icon: 'ic_operate'
      },
      {
        name: '故障处理',
        id: '6',
        icon: 'ic_fault'
      },
      {
        name: '参考指南',
        id: '7',
        icon: 'ic_guide'
      },
      {
        name: '二次开发',
        id: '8',
        icon: 'ic_develop'
      },
      {
        name: '其他',
        id: '9',
        icon: 'ic_else'
      },
      {
        name: '文档合集',
        id: '10',
        icon: 'ic_all'
      },
      {
        name: '操作维护',
        id: '11',
        icon: 'ic_operate'
      },
      {
        name: '故障处理',
        id: '12',
        icon: 'ic_fault'
      },
      {
        name: '参考指南',
        id: '13',
        icon: 'ic_guide'
      },
      {
        name: '二次开发',
        id: '14',
        icon: 'ic_develop'
      },
      {
        name: '其他',
        id: '15',
        icon: 'ic_else'
      },
      {
        name: '文档合集',
        id: '16',
        icon: 'ic_all'
      }
    ])
    const docDetailList = ref([
      // {
      //   label: "安全注意事项",
      //   title: "服务器 安全信息 05",
      //   time: "2022-10-29",
      // },
      // {
      //   label: "软件部署指南",
      //   title: "服务器 操作系统 安装指南 07",
      //   time: "2022-10-29",
      // },
      // {
      //   label: "验收测试指南",
      //   title: "FusionServer 1288H V5&2288H V5 服务器 验收测试指南 02",
      //   time: "2022-10-29",
      // },
      // {
      //   label: "用户指南",
      //   title: "FusionServer 机架服务器 iBMC (V561及以上) 用户指南 09",
      //   time: "2022-10-29",
      // },
      // {
      //   label: "高危操作",
      //   title: "服务器 高危操作场景汇总 05",
      //   time: "2022-10-29",
      // },
      // {
      //   label: "软件部署指南",
      //   title: "服务器 操作系统 安装指南 07",
      //   time: "2022-10-29",
      // },
    ])
    const docNavList = ref([
      // {
      //   name: "了解产品",
      //   id: "1",
      //   icon: "ic_understandproduct",
      // },
      // {
      //   name: "快速系列",
      //   id: "2",
      //   icon: "ic_fast",
      // },
      // {
      //   name: "安装升级",
      //   id: "3",
      //   icon: "ic_upgradation",
      // },
      // {
      //   name: "配置调试",
      //   id: "4",
      //   icon: "ic_configuration",
      // },
      // {
      //   name: "操作维护",
      //   id: "5",
      //   icon: "ic_operate",
      // },
      // {
      //   name: "故障处理",
      //   id: "6",
      //   icon: "ic_fault",
      // },
      // {
      //   name: "参考指南",
      //   id: "7",
      //   icon: "ic_guide",
      // },
      // {
      //   name: "二次开发",
      //   id: "8",
      //   icon: "ic_develop",
      // },
      // {
      //   name: "其他",
      //   id: "9",
      //   icon: "ic_else",
      // },
      // {
      //   name: "文档合集",
      //   id: "10",
      //   icon: "ic_all",
      // },
      // {
      //   name: "操作维护",
      //   id: "11",
      //   icon: "ic_operate",
      // },
      // {
      //   name: "故障处理",
      //   id: "12",
      //   icon: "ic_fault",
      // },
      // {
      //   name: "参考指南",
      //   id: "13",
      //   icon: "ic_guide",
      // },
      // {
      //   name: "二次开发",
      //   id: "14",
      //   icon: "ic_develop",
      // },
      // {
      //   name: "其他",
      //   id: "15",
      //   icon: "ic_else",
      // },
      // {
      //   name: "文档合集",
      //   id: "16",
      //   icon: "ic_all",
      // },
    ])
    onMounted(() => {
      getList()
    })
    // 移除监听器
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onScroll)
    })
    const getList = () => {
      if (route.query) {
        if (route.query.productModelMsg) {
          var data = JSON.parse(route.query.productModelMsg)
          http
            .request({
              method: 'post',
              url: '/website/product/home/queryProductModelRelevance',
              data: {
                keyword: route.query.keyword,
                productModelId: data.id,
                type: 1
              }
            })
            .then((res) => {
              docNavList.value = res.data
              docIconList.value.map((v) => {
                docNavList.value.map((s) => {
                  if (v.name === s.typeName) {
                    s.icon = v.icon
                  }
                })
              })
              if (docNavList.value.length > 0) {
                currentDoc.value = docNavList.value[0].id
              }
              // 监听滚动事件
              window.addEventListener('scroll', onScroll)
            })
        }
      }
    }
    function handleDownload (item) {
      const iframe = document.createElement('iframe')
      iframe.src = `/api/website/picture/home/downloadFile?filePath=${item.picture}`
      iframe.style.display = 'none'
      document.body.appendChild(iframe)
      setTimeout(() => {
        document.body.removeChild(iframe)
      }, 1000)
    }
    const handleJump = (item) => {
      var data = JSON.parse(route.query.productModelMsg)
      item.productName = data.productName
      item.productModel = data.productModel
      const routeData = router.resolve({
        path: '/document-detail',
        query: {
          detail: JSON.stringify(item)
        }
      })
      window.open(routeData.href, '_blank')
    }
    // 滚动监听器
    function onScroll () {
      const bannerHeight = document.getElementsByClassName('banner')[0].offsetHeight + 84
      windowBannerHeight.value = bannerHeight
      // 获取所有锚点元素
      const navContents = document.querySelectorAll('.docRight ul')
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop + bannerHeight)
      })
      // 获取当前文档流的 scrollTop
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      windowScrollTop.value = scrollTop
      // 定义当前点亮的导航下标
      let navIndex = 0
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        if (scrollTop >= offsetTopArr[n]) {
          navIndex = n
        }
      }
      // 把下标赋值给 vue 的 data
      currentDoc.value = docNavList.value[navIndex].id

      // 判断若接近滚动至底部footer则计算左侧导航高度进行滚动显示
      const footerTop = document
        .getElementsByClassName('footer')[0]
        .getBoundingClientRect().top
      scrollHeight.value = footerTop
      document.getElementsByClassName('left-navigate')[0].style.overflowX = 'hidden'
      document.getElementsByClassName('left-navigate')[0].style.overflowY = 'auto'
    }
    const goAnchor = (id) => {
      var anchor = document.getElementById(id)
      anchor.scrollIntoView()
    }
    const handleClick = (item) => {
      currentDoc.value = item.id
    }
    return {
      docNavList,
      currentDoc,
      windowScrollTop,
      windowBannerHeight,
      goAnchor,
      handleClick,
      docDetailList,
      scrollHeight,
      handleJump,
      handleDownload
    }
  }
})
</script>

<style lang="scss" scoped>
.title-svg {
  width: 20px;
  height: 20px;
}
.label-svg {
  width: 16px;
  height: 16px;
}
.doc {
  height: 100%;
  min-height: 850px;
  padding-top: 24px;
  width: 100%;
  overflow: hidden;
  .docRight {
    margin-left: 30px;
    ul {
      list-style: none;
      padding: 0;
    }
    .title {
      font-size: 14px;
      color: #474747;
      font-weight: 700;
      display: flex;
      align-items: center;
      .txt {
        margin-left: 10px;
      }
    }
    .table {
      background: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
      margin-top: 13px;
      .table-item {
        padding: 20px 20px 0px 20px;
        .label {
          font-size: 14px;
          color: #c0c2c5;
          font-weight: 400;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          svg {
            margin-left: 6px;
          }
        }
        .item {
          span {
            margin-right: 6px;
          }
          .item-title {
            cursor: pointer;
            &:hover {
              color: #0c5dff;
            }
          }
          svg {
            margin-left: 10px;
          }
        }
        &:last-child {
          padding-bottom: 20px;
        }
      }
    }
  }
}
.left-navigate {
  height: 100%;
  min-width: 180px;
  top: 0;
  position: absolute;
  background: #eef3fe;
  width: 100%;
  &.active {
    position: fixed;
    width: calc(100% - 1665px);
  }
}
.left-scroll {
  width: 100%;
  overflow-y: auto;
  padding: 10px 0px 0 15px;
  ul {
    font-size: 16px;
    li {
      line-height: 26px;
      list-style-type: square;
      text-align: left;
      position: relative;
      cursor: pointer;
      color: #474747;
      a {
        text-decoration: none;
        background-color: none;
        color: #474747;
        &:hover {
          color: #0c5dff;
        }
      }
    }
    li::marker {
      font-size: 22px;
      color: #b8cefa;
    }
    li.active {
      color: #0c5dff;
      font-weight: 500;
      a {
        color: #0c5dff;
      }
    }
    li.active::marker {
      font-size: 22px;
      color: #0c5dff;
    }
  }
}
</style>
