<template>
  <div class="case" v-if="!blankVisible">
    <div class="check-box">
      <a-form>
        <a-form-item label="版本"> <a-select allowClear></a-select></a-form-item>
      </a-form>
    </div>
    <div class="table">
      <a-table
        :dataSource="dataSource"
        :columns="columns"
        bordered
        :pagination="false"
        size="small"
        :customRow="rowClick"
      >
        <template #headerCell="{ column }">
          <template v-if="column.key === 'scene'">
            <div class="header">
              应用场景
              <svg-icon icon-class="ic_filter" class="head-icon"></svg-icon>
            </div>
          </template>
          <template v-if="column.key === 'fileLevel'">
            <div class="header">
              文档密级
              <a-popover title="立即登录 查看您的案例密级">
                <template #content>
                  <p>S级-仅限思腾工程师开放的通用案例</p>
                  <p>A级-面向服务渠道开放的通用案例</p>
                  <p>C级-面向购买产品的客户开放的通用案例</p>
                  <p>D级-面向注册用户开放的通用案例</p>
                  <p>E级-面向访客开放的通用案例</p>
                </template>
                <svg-icon icon-class="wenhao-grey" class="head-icon"></svg-icon>
              </a-popover>
            </div>
          </template>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'caseName'">
            <div class="header">
              {{ record.caseName }}
              <svg-icon
                icon-class="ic_lock"
                class="head-icon"
                v-if="record.auth == 1"
              ></svg-icon>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <div class="pagination-box">
      <a-pagination
        :total="total"
        v-model:current="searchForm.pageReq.pageNum"
        show-quick-jumper
        :showTotal="(total) => `共 ${total} 条`"
        size="small"
        show-size-changer
      ></a-pagination>
    </div>
  </div>
  <div class="blank" v-else>
    <div>
      <svg-icon icon-class="img_error_blank"></svg-icon>
      <div class="txt">暂无数据</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup () {
    const router = useRouter()
    const checked = ref()
    const total = ref(0)
    const blankVisible = ref(false)
    const searchForm = reactive({
      pageReq: {
        pageNum: 1,
        pageSize: 10
      }
    })
    const dataSource = ref([
      {
        caseName: 'FusionServer iDriver 3.0.118',
        scene: '故障处理',
        fileLevel: 'C',
        scanNum: 16,
        date: '2023-07-29',
        auth: 0
      },
      {
        caseName: 'FusionServer iDriver 3.0.118',
        scene: '故障处理',
        fileLevel: 'C',
        scanNum: 9,
        date: '2023-06-30',
        auth: 1
      },
      {
        caseName: 'FusionServer iDriver 3.0.118',
        scene: '故障处理',
        fileLevel: 'C',
        scanNum: 8,
        date: '2023-07-21',
        auth: 1
      },
      {
        caseName: 'FusionServer iDriver 3.0.118',
        scene: '故障处理',
        fileLevel: 'C',
        scanNum: 12,
        date: '2023-06-03',
        auth: 1
      }
    ])
    const columns = ref([
      {
        title: '版本',
        dataIndex: 'caseName',
        key: 'caseName',
        scopedSlots: { customRender: 'caseName' }
      },
      {
        title: '发布日期',
        dataIndex: 'date',
        key: 'date',
        scopedSlots: { customRender: 'date' },
        sorter: (a, b) => a.date - b.date,
        ellipsis: true
      }
    ])
    onMounted(() => {
      if (!dataSource.value) {
        blankVisible.value = true
      }
    })
    const handleJump = () => {}
    const rowClick = (record) => {
      return {
        onClick: (event) => {
          if (record.auth === 0) {
            // router.push({
            //   path: '/software-detail'
            // })
            const routeData = router.resolve({
              path: '/software-detail'
            })
            window.open(routeData.href, '_blank')
          }
        }
      }
    }
    return {
      checked,
      dataSource,
      columns,
      total,
      searchForm,
      handleJump,
      rowClick,
      blankVisible
    }
  }
})
</script>

<style lang="scss" scoped>
.case {
  height: 100%;
  display: flex;
  flex-direction: column;
  .check-box {
    padding-top: 22px;
    width: 50%;
  }
  .table {
    flex: 1;
    .header {
      display: flex;
      align-items: center;
      .head-icon {
        width: 14px;
        height: 14px;
        margin-left: 6px;
      }
    }
  }
  .pagination-box {
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: right;
  }
}
.blank {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  padding-bottom: 20%;
  .txt {
    font-size: 14px;
    color: #352f2f;
    text-align: center;
  }
  svg {
    height: 25rem;
    width: 25rem;
  }
}
</style>
