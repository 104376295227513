<template>
  <svg>
    <use :xlink:href="iconName"></use>
  </svg>
</template>
<script>
import { defineComponent, computed } from 'vue'
export default defineComponent({
  props: {
    iconClass: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const iconName = computed(() => {
      return `#icon-${props.iconClass}`
    })
    return {
      iconName
    }
  }
})
</script>
<style lang="scss" scoped>
  svg {
    vertical-align: middle;
  }
</style>
