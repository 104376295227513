import { createRouter, createWebHistory } from 'vue-router'
import Product from '@/views/product/Index'
import AlgorithmMall from '@/views/algorithm-mall/AlgorithmMall'
import ProductDetail from '@/views/product/ProductDetail'
import DocumentDetail from '@/views/product/component/DocumentDetail'
import CaseDetail from '@/views/product/component/CaseDetail'
import SoftWare from '@/views/product/component/SoftWare'
import SoftWareDetail from '@/views/product/component/SoftWareDetail'
import HistoryVersion from '@/views/product/component/HistoryVersion'
import AnnouncementDetail from '@/views/product/component/AnnouncementDetail'
import ProductSearch from '@/views/product/ProductSearch'
const routes = [
  {
    path: '/',
    name: 'Index',
    redirect: '/soft-support'
  },
  {
    path: '/algorithm-mall',
    name: 'AlgorithmMall',
    component: AlgorithmMall,
    meta: {
      title: '算法商城'
    }
  },
  {
    path: '/algorithm-mall-detail',
    name: 'AlgorithmMallDetail',
    component: () => import('@/views/algorithm-mall/AlgorithmMallDetail'),
    meta: {
      title: '算法商城详情'
    }
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    meta: {
      title: '技术支持'
    }
  },
  {
    path: '/soft-support',
    name: 'SoftSupport',
    component: () => import('@/views/soft-support/SoftSupport'),
    meta: {
      title: '软件产品'
    }
  },
  {
    path: '/product-detail',
    name: 'ProductDetail',
    component: ProductDetail,
    meta: {
      title: '产品详情'
    }
  },
  {
    path: '/document-detail',
    name: 'DocumentDetail',
    component: DocumentDetail,
    meta: {
      title: '文档详情'
    }
  },
  {
    path: '/case-detail',
    name: 'CaseDetail',
    component: CaseDetail,
    meta: {
      title: '案例详情'
    }
  },
  {
    path: '/soft-ware',
    name: 'SoftWare',
    component: SoftWare,
    meta: {
      title: '软件'
    }
  },
  {
    path: '/software-detail',
    name: 'SoftWareDetail',
    component: SoftWareDetail,
    meta: {
      title: '软件版本详情'
    }
  },
  {
    path: '/history-version',
    name: 'HistoryVersion',
    component: HistoryVersion,
    meta: {
      title: '历史版本'
    }
  },
  {
    path: '/announcement-detail',
    name: 'AnnouncementDetail',
    component: AnnouncementDetail,
    meta: {
      title: '产品公告详情'
    }
  },
  {
    path: '/product-search',
    name: 'ProductSearch',
    component: ProductSearch,
    meta: {
      title: '搜索详情'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
