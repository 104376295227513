<template>
  <div class="doc-detail-container">
    <div class="banner">
      <head-navigation class="txd"></head-navigation>
      <div class="rack-top">
        <div class="header">
          技术支持>>{{ form.productName }}>> {{ form.productModel }}>>><span
            >产品公告详情</span
          >
        </div>
        <div class="rack-top-detail">
          <div class="detail">
            <div class="label">{{ form.noticeName }}</div>
            <div class="msg">
              <a-row>
                <a-col :span="6">
                  <div class="col">
                    <span class="col-title">公告编号:</span>
                    <span class="col-content">{{ form.noticeCode }}</span>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="col">
                    <span class="col-title">发布时间:</span>
                    <span class="col-content">{{ form.publishTime }}</span>
                  </div>
                </a-col>
              </a-row>
              <!-- <a-row>
                <a-col :span="6"
                  ><div class="col">
                    <span class="col-title">浏览次数:</span>
                    <span class="col-content">7950</span>
                  </div></a-col
                >
              </a-row> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div style="width: 100%; height: 100%">
        <VueOfficePdf :src="pdfUrl" style="width: 100%; height: 100%" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeadNavigation from '@/components/HeadNavigation.vue'
import { defineComponent, onMounted, reactive, ref } from 'vue'
import Footer from '@/components/footer/Footer'
import { useRoute } from 'vue-router'
import http from '@/utils/http'
import VueOfficePdf from '@vue-office/pdf'
export default defineComponent({
  components: {
    HeadNavigation,
    Footer,
    VueOfficePdf
  },
  setup () {
    const route = useRoute()
    const form = reactive({})
    const pdfUrl = ref()
    onMounted(() => {
      getList()
    })
    const getList = () => {
      if (route.query) {
        http
          .request({
            method: 'get',
            url: `/website/product/home/queryProductNoticeById/${route.query.id}`
          })
          .then((res) => {
            if (res) {
              for (var i in res.data) {
                form[i] = res.data[i]
              }
              form.productName = route.query.productName
              form.productModel = route.query.productModel
              pdfUrl.value = '/api/website' + res.data.picture
            }
          })
      }
    }
    return {
      form,
      pdfUrl
    }
  }
})
</script>

<style lang="scss" scoped>
.doc-detail-container {
  .download-btn {
    margin-left: 8px;
    border: unset;
    background: #ffffff;
    padding: 5px 13px;
    cursor: pointer;
    font-size: 14px;
    color: #3273f9;
    letter-spacing: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .banner {
    height: 30%;
    border: 1px solid #979797;
    background: #3273f9;
    min-height: 330px;
    .txd {
      position: relative;
    }
    .header {
      margin: 0 auto;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: 0;
      font-weight: 500;
      span {
        color: #ffffff;
      }
    }
    .rack-top {
      padding: 0 19%;
      margin: 0 auto;
      .rack-top-detail {
        // display: flex;
        .detail {
          margin-top: 24px;
          .label {
            background-image: linear-gradient(
              -86deg,
              rgba(50, 115, 249, 0) 0%,
              rgba(255, 255, 255, 0.45) 100%
            );
            font-size: 30px;
            color: #ffffff;
            letter-spacing: 0;
            font-weight: 700;
            padding: 7px 9px 7px 9px;
          }
          .msg {
            margin-top: 10px;
            font-size: 16px;
            color: #ffffff;
            font-weight: 400;
            line-height: 32px;
            .col {
              align-items: center;
              // line-height: 42px;
            }

            .col-title {
              font-size: 14px;
              color: #ffffff;
              letter-spacing: 0;
              text-align: right;
              font-weight: 400;
              width: 70px;
              float: left;
              align-items: center;
            }

            .col-content {
              font-size: 14px;
              color: #ffffff;
              font-weight: 700;
              text-align: justify;
              word-break: break-all;
              display: flow-root;
            }
          }
        }
      }
    }
  }
  .content {
    height: 100vh;
    background: #f8f8fd;
    padding: 0 19%;
    padding-top: 24px;
  }
}
</style>
